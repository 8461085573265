import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagemTratamento {
  private urlBlobCache: Map<string, string> = new Map<string, string>();

  getImagemBase64(noticiaImagem: string): string | undefined {
    if (!noticiaImagem) {
      return '';
    }

    const byteCharacters = atob(noticiaImagem);
    const byteNumbers = new ArrayBuffer(byteCharacters.length);
    const uintArray = new Uint8Array(byteNumbers);

    for (let i = 0; i < byteCharacters.length; i++) {
      uintArray[i] = byteCharacters.charCodeAt(i);
    }

    const tipoImagem = this.verificarTipoImagem(uintArray);
    return 'data:' + tipoImagem + ';base64,' + noticiaImagem;
  }

  getImagemBlob(noticiaImagem: string): string | undefined {
    if (!noticiaImagem) {
      return '';
    }

    if (this.urlBlobCache.has(noticiaImagem)) {
      return this.urlBlobCache.get(noticiaImagem);
    } else {
      const blobImagem = this.base64toBlob(noticiaImagem);

      const blobUrl = (window.URL || window.webkitURL).createObjectURL(blobImagem);
      this.urlBlobCache.set(noticiaImagem, blobUrl);
      return blobUrl;
    }
  }

  private base64toBlob(base64: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new ArrayBuffer(byteCharacters.length);
    const uintArray = new Uint8Array(byteNumbers);

    for (let i = 0; i < byteCharacters.length; i++) {
      uintArray[i] = byteCharacters.charCodeAt(i);
    }

    const tipoImagem = this.verificarTipoImagem(uintArray);
    const blob = new Blob([byteNumbers], { type: tipoImagem });
    return blob;
  }

  private verificarTipoImagem(byteArray: Uint8Array): string {
    const signatures = new Map<number[], string>([
      [[0xff, 0xd8, 0xff], 'image/jpeg'],
      [[0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], 'image/png'],
      [[0x47, 0x49, 0x46, 0x38], 'image/gif'],
      [[0x49, 0x49, 0x2a, 0x00], 'image/tiff'],
      [[0x42, 0x4d], 'image/bmp'],
      [[0x52, 0x49, 0x46, 0x46, 0x57, 0x45, 0x42, 0x50], 'image/webp'],
      [[0x3c, 0x3f, 0x78, 0x6d, 0x6c, 0x20, 0x76, 0x65, 0x72, 0x73, 0x69, 0x6f, 0x6e, 0x3d], 'image/svg'],
      [[0x00, 0x00, 0x01, 0x00], 'image/ico']
    ]);

    for (const [signature, type] of signatures.entries()) {
      if (byteArray.length >= signature.length) {
        let match = true;
        for (let i = 0; i < signature.length; i++) {
          if (byteArray[i] !== signature[i]) {
            match = false;
            break;
          }
        }
        if (match) {
          return type;
        }
      }
    }
    console.error(`Tipo de imagem não identificado`);
    return 'application/octet-stream';
  }

  revogarUrlBlob(): void {
    for (const urlBlob of this.urlBlobCache.values()) {
      URL.revokeObjectURL(urlBlob);
    }
    this.urlBlobCache.clear();
  }
}
