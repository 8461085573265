<div class="container">
  <br />
  <h1 class="titulo">Visualizar Noticia</h1>
  <br />
  <div class="container-campos">
    <div class="d-flex row" style="margin-bottom: 20px">
      <div class="col-md-12 titulo-campo-saida">
        <label for="titulo">Título da Notícia:</label>
        <output>{{ noticias.titulo }}</output>
      </div>
    </div>
    <div class="d-flex row">
      <div class="col-md-3 titulo-campo-saida">
        <label for="dataPublicacao">Data de Publicação:</label>
        <output>{{ noticias.dataPublicacao }}</output>
      </div>
      <div class="col-md-3 titulo-campo-saida">
        <label for="categoria">Categoria:</label>
        <output>{{ noticias.categoria.nome }}</output>
      </div>
      @if (campoDestaque) {
        <div class="col-md-3 titulo-campo-saida">
          <label for="destaque">Destaque:</label>
          <output>{{ noticias.destaque !== null ? noticias.destaque : "Nenhuma" }}</output>
        </div>
      }
    </div>
    @if (campoAcesso) {
      <div class="col-md-9 titulo-campo-saida">
        <label for="acesso" class="label-acesso">Acesso Registro:</label>
        <output class="output">{{ noticias.acesso.nome }}</output>
      </div>
    }
    <div>
      <label for="texto" class="label-acesso">Texto:</label>
      <p class="text-noticia" [innerHTML]="processarTexto(noticias?.texto)"></p>
    </div>
    <br />
    @if (noticias?.palavraChave) {
      <div>
        <mat-label>Palavras-chave:</mat-label>
        <mat-chip-set>
          @for (palavraChave of palavrasChaves(); track $index) {
            <mat-chip-row>
              {{ palavraChave }}
            </mat-chip-row>
          }
        </mat-chip-set>
      </div>
    }
    <div class="alinhar-colunas">
      @if (campoCapa && noticias?.imagem) {
        <div class="div-pdf">
          <label for="imagem">Capa da Notícia:</label>
          <img id="imagem" alt="Imagem da Notícia" class="imagem-noticia" [src]="obterImagem()" />
        </div>
      }
      @if (arquivos?.length > 0) {
        <div class="div-pdf">
          <label for="pdfs">PDFs:</label>
          <div id="pdfs">
            <table>
              <tbody>
                @for (item of arquivos; track item; let i = $index) {
                  <tr>
                    <td>
                      <a href="#" (click)="abrirArquivo($event, item)"><mat-icon fontIcon="cloud_download" />{{ item.nome }}</a>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      }
      @if (listaImagens.length > 0) {
        <div>
          <label for="pdfs">Imagens:</label>
          <div id="pdfs">
            <table>
              <tbody>
                @for (item of listaImagens; track item; let i = $index) {
                  <tr>
                    <td>
                      <a href="#" (click)="abrirArquivo($event, item)"><mat-icon fontIcon="cloud_download" />{{ item.nome }}</a>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
    <br />
    <div class="campos-direita"><button type="button" class="btn btn-outline-dark" (click)="voltar()">Voltar</button>&nbsp;</div>
    <br />
  </div>
</div>
