import { Component, OnInit } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import { Noticias } from 'src/app/shared/models/noticia.interface';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-comunicados',
  templateUrl: './comunicados.component.html',
  styleUrl: './comunicados.component.sass',
  standalone: true,

  imports: [SeparadorComponent, MatPaginator]
})
export class ComunicadosComponent implements OnInit {
  objComunicados?: Noticias[] = [];

  pageEvent: PageEvent;
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  constructor(
    private noticiaService: NoticiaService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.carregarComunicado();
  }

  carregarComunicado(): void {
    this.noticiaService.getNoticia(this.pageIndex, this.pageSize, CategoriaNoticia.COMUNICADOS).subscribe(
      (response: Page<Noticias>) => {
        this.objComunicados = response.content;
        this.length = response.totalElements;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  comunicado(id: number): void {
    this.router.navigate(['informacao-cidadao/comunicado', id]);
  }

  editarData(data: string): string {
    const ano = data.substring(6, 10);
    const mes = this.transformarMes(data.substring(3, 5));
    const dia = data.substring(0, 2);
    return `<strong>${ano}</strong> ${dia} - ${mes}`;
  }

  transformarMes(mes: string): string {
    const mesEditado = Number(mes);
    const month = Number(mesEditado);
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    return months[month - 1];
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.carregarComunicado();
  }
}
