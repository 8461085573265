import { environment } from 'src/environments/environment';
import { MenuItem } from './menu.model';

export const MenuList: MenuItem[] = [
  {
    label: 'INSTITUCIONAL',
    children: [
      {
        label: 'Sobre a PGE',
        externo: false,
        redirect: 'menu/INSTITUCIONAL'
      },

      {
        label: 'Áreas de Atuação',
        externo: false,
        redirect: 'menu/AREASATUACAO'
      },

      {
        externo: true,
        redirect: 'pdf/carta-de-servicos-pge-2024.pdf',
        label: 'Carta de Serviços'
      },

      {
        label: 'Corregedoria',
        externo: false,
        redirect: 'menu/CORREGEDORIA'
      },

      {
        label: 'Conselho',
        externo: false,
        redirect: 'menu/CONSELHO'
      },

      {
        label: 'Ouvidoria',
        externo: false,
        redirect: 'menu/OUVIDORIA'
      }
    ]
  },

  {
    label: 'INFORMAÇÃO AO CIDADÃO',
    children: [
      {
        externo: false,
        redirect: 'informacao-cidadao/atendimento-protestos-icms-ipva-itcmd-certidao-negativa',
        label: 'Atendimento sobre protestos/ ICMS / IPVA / ITCMD / Certidão Negativa'
      },
      {
        externo: false,
        redirect: 'informacao-cidadao/cepen',
        label: 'Certidão Positiva com Efeito de Negativa (Cepen)'
      },
      {
        externo: false,
        redirect: 'informacao-cidadao/credenciamento-contadores',
        label: 'Credenciamento de contadores'
      },
      {
        externo: true,
        redirect: 'https://www.pge.sp.gov.br/editais/licitacoes/index.aspx',
        label: 'Compras e Contratações'
      },
      {
        externo: false,
        redirect: 'informacao-cidadao/enderecos-telefones-uteis',
        label: 'Endereços e Telefones Úteis'
      },
      {
        externo: true,
        redirect: 'pdf/entidades-representadas-pela-pge.pdf',
        label: 'Entidades representadas pela PGE'
      },
      {
        externo: false,
        redirect: 'informacao-cidadao/localizar-regionais',
        label: 'Localize a Regional'
      }
    ]
  },
  {
    label: 'CENTRO DE ESTUDOS',
    children: [
      {
        externo: true,
        redirect: 'https://www.pge.sp.gov.br/CentroEstudo/Default.htm',
        label: 'Site CE'
      },
      {
        externo: true,
        redirect: 'http://biblioteca.pge.sp.gov.br',
        label: 'Biblioteca'
      },
      {
        externo: true,
        redirect: 'https://revistas.pge.sp.gov.br',
        label: 'Publicações'
      },
      {
        externo: true,
        redirect: environment.siteEscola,
        label: 'Escola Superior da PGE'
      }
    ]
  },
  {
    externo: false,
    redirect: 'login',
    label: 'ÁREA RESTRITA'
  }
];
