import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { AuthenticationService } from 'src/app/area-restrita/shared/services/authentication.service';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { UsuarioLogin } from 'src/app/shared/models/usuario-login.interface';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { LegadoUsuarioService } from '../../auxilio/shared/services/legado-usuario.service';
import { ShaService } from '../../auxilio/shared/services/sha.service';
import { FundoAreaRestritaPgeComponent } from '../shared/components/fundo-area-restrita-pge/fundo-area-restrita-pge.component';
import { SenhaService } from '../shared/services/senha.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
@Component({
  selector: 'app-login-card',
  templateUrl: './login-card.component.html',
  styleUrl: './login-card.component.sass',
  standalone: true,
  imports: [FundoAreaRestritaPgeComponent, FormsModule, MatButtonModule, CommonModule, MatIconModule]
})
export class LoginCardComponent implements OnInit {
  rotaPadrao: string = '@@ENV@@/SitePge/Login.aspx?ReturnUrl=%2fSitePge%2frestrito%2fdefault.aspx';
  rotaLegado: string = '@@ENV@@';
  novoRedirecionamento: string = '';
  senhaVisivel: boolean = false;
  env = environment;
  urlLoginLegado: string;
  versao = Globais.versionFront;

  usuario = '';
  senha = '';
  eErro = false;
  mensagemErro = '';

  ngOnInit() {
    this.rotaPadrao = this.rotaPadrao.replace('@@ENV@@', this.env.apiLegate);
    this.rotaPadrao = this.rotaPadrao.substring(0, this.rotaPadrao.indexOf('@@USER@@') - 9);
    this.rotaLegado = this.rotaLegado.replace('@@ENV@@', this.env.apiLegate);
  }

  constructor(
    private authenticationService: AuthenticationService,
    private rota: Router,
    private localStorage: LocalStorageService,
    private readonly senhaService: SenhaService,
    private legadoService: LegadoUsuarioService,
    private toastr: ToastService,
    private cryptService: ShaService
  ) {}

  toggleSenhaVisivel() {
    this.senhaVisivel = !this.senhaVisivel;
  }

  gerenteLegado() {
    if (this.usuario != null && this.usuario.toString().trim() != '') {
      const us = this.cryptService.executaHashing(this.usuario) + '&senha=' + this.cryptService.executaHashing(this.senha);
      this.novoRedirecionamento = this.rotaLegado.replace('@@USER@@', us);
    } else {
      this.novoRedirecionamento = this.rotaPadrao;
    }
  }

  direcionaLegado() {
    window.location.href = this.novoRedirecionamento;
  }

  login() {
    this.localStorage.clear();
    if (!this.usuario || !this.senha) {
      this.eErro = true;
      this.mensagemErro = 'Usuário/Senha incorretos!';
      console.error('Usuário/Senha incorretos!');
    } else {
      this.authenticationService.login(this.usuario, this.senha).subscribe({
        next: (resp: HttpResponse<UsuarioLogin>) => {
          if (resp.status == 200) {
            this.authenticationService.setToken(resp.headers.get('Authorization')!);
            localStorage.setItem('senhaExpirada', resp.body.senhaExpirada.toString());
            localStorage.setItem('cpf', resp.body.cpf);
            localStorage.setItem('usuarioLegado', '0');
            this.authenticationService.setIdUsuario(resp.body.idUsuario);
            if (this.redirecionarTrocaSenha(resp.body.senhaExpirada)) {
              console.error('Senha expirou');
              return;
            }
            this.eErro = false;
            this.gerenteLegado();

            const config = new MatSnackBarConfig();
            config.duration = 3000;

            //Verifica se o usuario esta no legado - True = Vai para o legado e o novo, False = Somente vai para o novo.
            this.legadoService.checkUsuarioLegado(this.localStorage.get('cpf')).subscribe({
              next: () => {
                this.toastr.info('Usuario encontrado no legado!', 'Aviso do Sistema', config);
                localStorage.setItem('usuarioLegado', '1');
                setTimeout(() => {
                  if (environment.env.toLocaleLowerCase().trim().indexOf('hml') >= 0 || environment.env.toLocaleLowerCase().trim().indexOf('prd') >= 0)
                    this.direcionaLegado();
                  else {
                    window.open(this.novoRedirecionamento, '_blank');
                    this.rota.navigate(['/area-restrita/home']);
                  }
                }, 1500);
              },
              error: (error: HttpErrorResponse) => {
                if (error.status == 422) {
                  this.toastr.info('Usuario não encontrado no legado, será levado somente para o novo sistema!', 'Aviso do Sistema', config);
                  this.rota.navigate(['/area-restrita/home']);
                } else {
                  this.toastr.error('Erro ao verificar usuario no legado.', 'Erro do Sistema', config);
                  this.rota.navigate(['/area-restrita/home']);
                }
              }
            });
          }
        },
        error: () => {
          Globais.isLogin = false;
          Globais.idUsuario = 0;
          Globais.tokenBearer = '';
          this.eErro = true;
          this.mensagemErro = 'Usuário/Senha incorretos!';
          this.novoRedirecionamento = this.rotaPadrao;
        }
      });
    }
  }
  redirecionarParaRota(evento: Event): void {
    evento.preventDefault(); // Prevenir o comportamento padrão do link
    if (!this.usuario) {
      this.eErro = true;
      this.mensagemErro = 'Favor preencher o usuário';
      return;
    }
    this.senhaService.esqueciSenha(this.usuario).subscribe(
      (resp: UsuarioLogin) => {
        this.senhaService.setUserInfo(resp);
        this.rota.navigate(['/esqueci-senha']);
      },
      (error) => {
        this.eErro = true;
        this.mensagemErro = 'Usuário não encontrado';
        const mensagem = error.error.erros?.['Erro de negócio'] || 'Erro ao validar usuário!';
        this.toastr.error(mensagem);
      }
    );
  }

  redirecionarTrocaSenha(senhaExpirada: boolean) {
    if (senhaExpirada) {
      this.rota.navigate(['/alterar-senha']);
      return true;
    }
    return false;
  }
}
