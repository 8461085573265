import { CurrencyPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { Observable, Subject, finalize, takeUntil } from 'rxjs';
import { AuxilioSaudeMesaAnalise, Perfil, StatusAuxilio } from 'src/app/area-restrita/features/auxilio/saude/shared/models/auxilio-saude';
import { MesaAuxilioSaudeService } from 'src/app/area-restrita/features/auxilio/saude/shared/services/mesa-auxilio-saude.service';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { Meses } from 'src/app/area-restrita/shared/models/global-interface';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { FormatBRLPipe } from '../../../../shared/pipes/format-brl.pipe';
import { MesPorExtensoPipe } from '../../../../shared/pipes/mes-por-extenso.pipe';
import { StateAnaliseService } from '../../shared/services/state-analise.service';

import { AuxilioStatusEnum } from '../shared/enums/auxilio-status.enum';
import { SolicitacaoAuxilioSaudeService } from '../shared/services/solicitacao-auxilio-saude.service';

@Component({
  selector: 'app-mesa-analise-pedido-auxilio-saude',
  templateUrl: './mesa-analise-pedido-auxilio-saude.component.html',
  styleUrl: './mesa-analise-pedido-auxilio-saude.component.sass',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatLabel,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton,
    RouterLink,
    MatIcon,
    MatTooltip,
    MatPaginator,
    CurrencyPipe,
    FormatBRLPipe,
    MesPorExtensoPipe,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MesaAnalisePedidoAuxilioSaudeComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  pedidosAuxilio = signal<AuxilioSaudeMesaAnalise[]>([]);

  tiposAuxilioSaude = toSignal(this.solicitacaoAuxilioSaudeService.obterListaTipoAuxilioSaude());

  pedidosSelecionados: Set<number> = new Set<number>();
  statusAuxilio = signal<StatusAuxilio[]>([]);
  perfil: Perfil[];
  meses: Observable<Meses[]> | undefined;
  verificado = true;
  paginador = false;
  checkboxCabecalhoSelecionado: boolean = false;
  mostraTabela = signal<boolean>(false);
  ativaBotao: boolean = true;

  eventoPagina: PageEvent;
  comprimento = 0;
  tamanhoDaPagina = 10;
  numeroPagina = 0;
  opcoesDoTamanhoDaPagina = [5, 10, 25];

  ocultarTamanhoDaPagina = false;
  mostrarOpcoesDeTamanhoPagina = true;
  mostrarPrimeiroUltimosBotoes = true;
  desabilitado = false;

  isAnaliseInscricao = false;

  constructor(
    private solicitacaoAuxilioSaudeService: SolicitacaoAuxilioSaudeService,
    private mesaAuxilioSaudeService: MesaAuxilioSaudeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastService,
    private localStorage: LocalStorageService,
    private waitLoadingService: WaitLoadingService,
    private stateAnaliseService: StateAnaliseService,
    private paginaService: PaginaVisitadaService
  ) {}

  ngOnInit(): void {
    this.paginaService.salvaPagina('Analisar Pedido de Auxílio Saúde').subscribe();
    this.isAnaliseInscricao = this.router.url.includes('analisar-inscricao');
    this.numeroPagina = this.stateAnaliseService.page;
    this.tamanhoDaPagina = this.stateAnaliseService.size;
    this.stateAnaliseService.limpar();
    this.recuperarFiltro();
    this.obterTiposStatus();
    this.obterPerfis();

    setTimeout(() => {
      this.buscarPedidos();
    }, 1000);
  }

  dadosFormulario = this.formBuilder.group({
    procuradorServidor: [this.stateAnaliseService.procuradorServidor],
    status: [this.stateAnaliseService.status ?? '1'],
    perfil: [this.stateAnaliseService.perfil],
    tipoAuxilioSaude: [this.stateAnaliseService.tipoAuxilioSaude],
    dataAprovacaoPGE: [this.obterDataAtual()],
    mesAnoSolicitacao: [this.stateAnaliseService.mesAnoSolicitacao],
    periodoDe: [this.stateAnaliseService.periodoDe],
    periodoAte: [this.stateAnaliseService.periodoAte],
    mesAnoOrdem: [this.stateAnaliseService.mesAnoOrdem]
  });

  lidarOrnadacao(): void {
    this.dadosFormulario.patchValue({
      mesAnoOrdem: !this.dadosFormulario.controls.mesAnoOrdem.value
    });
    this.buscarPedidos();
  }

  buscarPedidos() {
    if (this.dadosFormulario.valid) {
      this.waitLoadingService.open();

      this.localStorage.setJson('filtro', this.dadosFormulario.value);

      this.mesaAuxilioSaudeService
        .buscarPedidos(this.dadosFormulario, this.numeroPagina, this.tamanhoDaPagina)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.waitLoadingService.close())
        )
        .subscribe({
          next: (response: Page<AuxilioSaudeMesaAnalise>) => {
            this.pedidosAuxilio.set(response.content);
            this.comprimento = response.totalElements;
            this.paginador = !response.empty;
            this.mostraTabela.set(true);
          },
          error: (error) => this.toastr.error(error.message)
        });
    }
  }

  avaliarPedido(pedido) {
    this.stateAnaliseService.procuradorServidor = this.dadosFormulario.get('procuradorServidor').value;
    this.stateAnaliseService.status = this.dadosFormulario.get('status').value;
    this.stateAnaliseService.perfil = this.dadosFormulario.get('perfil').value;
    this.stateAnaliseService.mesAnoSolicitacao = this.dadosFormulario.get('mesAnoSolicitacao').value;
    this.stateAnaliseService.periodoDe = this.dadosFormulario.get('periodoDe').value;
    this.stateAnaliseService.periodoAte = this.dadosFormulario.get('periodoAte').value;
    this.stateAnaliseService.mesAnoOrdem = this.dadosFormulario.get('mesAnoOrdem').value;
    this.stateAnaliseService.telaAnalise = true;
    this.stateAnaliseService.page = this.numeroPagina;
    this.stateAnaliseService.size = this.tamanhoDaPagina;
    if (!this.isAnaliseInscricao) {
      this.router.navigate(['area-restrita', 'analise-pedido-auxilio-saude', pedido.id]);
      return;
    }
    this.router.navigate(['area-restrita', 'inscricao-auxilio-avaliacao', pedido.usuario.id]);
  }

  deferirPedidos() {
    this.mesaAuxilioSaudeService
      .deferirPedidos(this.pedidosSelecionados)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.toastr.success(response);
          this.buscarPedidos();
          this.pedidosSelecionados = new Set<number>();
          this.checkboxCabecalhoSelecionado = false;
        },
        (error: HttpErrorResponse) => {
          this.toastr.error('Ocorreu um erro ao deferir os pedidos: ' + error.message);
        }
      );
  }

  indeferirPedidos() {
    this.mesaAuxilioSaudeService
      .indeferirPedidos(this.pedidosSelecionados)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.toastr.success(response);
          this.buscarPedidos();
          this.pedidosSelecionados = new Set<number>();
          this.checkboxCabecalhoSelecionado = false;
        },
        error: (error: HttpErrorResponse) => {
          console.log('error', error);
          this.toastr.error('Ocorreu um erro ao indeferir os pedidos:. ' + error.message);
        }
      });
  }

  VerificarPedidoSelecionado(pedido: AuxilioSaudeMesaAnalise) {
    if (pedido.podeDeferir) {
      const pedidoId = Number.parseInt(pedido.id);

      if (this.pedidosSelecionados.has(pedidoId)) {
        this.pedidosSelecionados.delete(pedidoId);
      } else {
        this.pedidosSelecionados.add(pedidoId);
      }

      this.ativaBotao = this.pedidosSelecionados.size == 0;
    }
    const todosSelecionados = this.pedidosAuxilio()
      .filter((pedido) => pedido.podeDeferir)
      .every((pedido) => this.pedidosSelecionados.has(Number.parseInt(pedido.id)));
    this.checkboxCabecalhoSelecionado = todosSelecionados;
  }

  obterTiposStatus() {
    this.mesaAuxilioSaudeService
      .obterListaStatusAuxilio()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: StatusAuxilio[]) => {
          this.statusAuxilio.set(dados);
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  obterPerfis() {
    this.waitLoadingService.open();
    this.mesaAuxilioSaudeService
      .obterListaPerfis()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.waitLoadingService.close())
      )
      .subscribe({
        next: (dados) => {
          this.perfil = dados;
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  limparFormulario() {
    this.dadosFormulario.reset({
      procuradorServidor: '',
      status: '',
      perfil: '',
      mesAnoSolicitacao: '',
      periodoDe: '',
      periodoAte: '',
      mesAnoOrdem: false
    });
    this.localStorage.remove('filtro');
    this.comprimento = 0;
    this.tamanhoDaPagina = 10;
    this.numeroPagina = 0;
    this.pedidosSelecionados.clear();
    this.pedidosAuxilio.set([]);
    this.verificado = true;
    this.paginador = false;
    this.mostraTabela.set(false);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selecionarTodos(event: Event) {
    const estaVerificado = (event.target as HTMLInputElement).checked;
    this.checkboxCabecalhoSelecionado = estaVerificado;

    this.pedidosAuxilio().forEach((pedido) => {
      if (pedido.podeDeferir) {
        if (estaVerificado) {
          this.pedidosSelecionados.add(Number.parseInt(pedido.id));
        } else {
          this.pedidosSelecionados.delete(Number.parseInt(pedido.id));
        }
      }
    });
    this.ativaBotao = !estaVerificado || this.pedidosSelecionados.size == 0;
  }

  obterDataAtual(): string {
    const dataAtual = new Date();
    const ano = dataAtual.getFullYear();
    const mes = ('0' + (dataAtual.getMonth() + 1)).slice(-2);
    const dia = ('0' + dataAtual.getDate()).slice(-2);
    return `${ano}-${mes}-${dia}`;
  }

  lidarEventoPagina(e: PageEvent) {
    this.eventoPagina = e;
    this.comprimento = e.length;
    this.tamanhoDaPagina = e.pageSize;
    this.numeroPagina = e.pageIndex;
    this.buscarPedidos();
  }

  recuperarFiltro(): void {
    const filtro = this.localStorage.getJson('filtro') ?? { status: AuxilioStatusEnum.AGUARDANDO_AVALIACAO };

    this.dadosFormulario.patchValue(filtro);
  }

  private existeValorNoFiltro(filtro): boolean {
    return Object.values(filtro).some((value) => !!value);
  }
}
