import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { PrimeTemplate } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { SeparadorComponent } from '../../core/layout/separador/separador.component';

@Component({
  selector: 'app-procurando-saber',
  templateUrl: './procurando-saber.component.html',
  styleUrl: './procurando-saber.component.sass',
  animations: [trigger('fadeOut', [transition(':leave', [animate('0.1s', style({ opacity: 0 }))])])],
  standalone: true,

  imports: [SeparadorComponent, CarouselModule, PrimeTemplate, MatIcon]
})
export class ProcurandoSaberComponent {
  cardContent = [
    {
      opniao: '“Na minha opinião, todos deveriam passar por esse projeto”',
      autor: 'EE Professor Alberto Levy - São Paulo – Grupo 4'
    },
    {
      opniao: '“Foi bom para aprofundar e me aprimorar na advocacia”',
      autor: 'EE Carlos Augusto de Freitas Villalva Junior - São Paulo – Grupo 5'
    },
    {
      opniao: '“Gostaria que tivesse mais vezes, uma vez por mês”',
      autor: 'EE Carlos Augusto de Freitas Villalva Junior - São Paulo – Grupo 5'
    },
    {
      opniao: '“Eu mesmo aprendi muito, são coisas que não temos na escola. Aprendi todas as funções que compõem o sistema de justiça”',
      autor: 'EE Padre Antonio Vieira - São Paulo – Grupo 7'
    },
    {
      opniao: '“Gostei muito, pois isso atrai muito para sermos advogados ou alguma organização pública que tem interesse. Gostei muito!”',
      autor: 'EE Professor Alberto Levy - São Paulo – Grupo 4'
    }
  ];
  cardImagem = [
    {
      src: 'images/procurando-saber/procurando-saber1.png',
      descricao: 'Dinâmica “Jogo dos Atores”'
    },
    {
      src: 'images/procurando-saber/procurando-saber2.png',
      descricao: 'Dinâmica “Jogo dos Atores”'
    },
    {
      src: 'images/procurando-saber/procurando-saber3.png',
      descricao: ''
    },
    {
      src: 'images/procurando-saber/procurando-saber4.png',
      descricao: 'Dinâmica “Jogo de Simulação”'
    },
    {
      src: 'images/procurando-saber/procurando-saber5.png',
      descricao: 'Dinâmica “Jogo de Simulação”'
    }
  ];
  responsiveConfig = [
    {
      breakpoint: '1280px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '1024px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '750px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '480px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  ciclo = [
    {
      title: 'Primeiro Ciclo',
      isOpen: false
    },
    {
      title: 'Segundo Ciclo',
      isOpen: false
    }
  ];

  toggle(index: number): void {
    this.ciclo[index].isOpen = !this.ciclo[index].isOpen;
    this.ciclo.forEach((item, i) => {
      if (i !== index) item.isOpen = false;
    });
  }
}
