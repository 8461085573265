// perfil.enum.ts

// Definição do enum
export enum PerfilEnum {
  Servidor = '0',
  Procurador = '1',
  Aposentado = '2',
  Falecido = '3',
  Exonerado = '4',
  Transf_Def_Pub = '5',
  Demitido = '6',
  Autarquia = '7'
}

export function obterPerfilPorId(id: string): PerfilEnum | undefined {
  return Object.values(PerfilEnum).find((p) => p === id);
}
