import { Component, input } from '@angular/core';

@Component({
  selector: 'app-separador',
  templateUrl: './separador.component.html',
  styleUrl: './separador.component.sass',
  standalone: true
})
export class SeparadorComponent {
  label = input.required<string>();
}
