<div class="container">
  <br />
  <h1 class="card-title titulo">Analisar Inscrição no Auxílio Saúde</h1>
  <br />
  <div class="row g-3">
    <form [formGroup]="dadosFormulario" (ngSubmit)="buscarPedidos()">
      <div class="d-flex row">
        <div class="col-md-6">
          <mat-label for="procuradorServidor" class="form-label" id="procuradorServidor">Procurador/Servidor:</mat-label>
          <mat-form-field class="mat-form-control">
            <input matInput placeholder="" formControlName="procuradorServidor" />
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-label for="status" class="form-label" id="status">Status:</mat-label>
          <mat-form-field class="mat-form-control">
            <mat-select formControlName="status" (keyup.enter)="buscarPedidos()">
              @for (s of statusAuxilio; track s) {
                <mat-option [value]="s.id">{{ s.nome }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-label for="dataEnvio" class="form-label" id="dataEnvio">Data da Inscrição:</mat-label>
          <mat-form-field class="mat-form-control" (keyup.enter)="buscarPedidos()">
            <input matInput formControlName="dataEnvio" [matDatepicker]="pickerDe" />
            <mat-datepicker-toggle matIconSuffix [for]="pickerDe" />
            <mat-datepicker #pickerDe />
          </mat-form-field>
        </div>
      </div>
      <br />
      <div class="d-flex row">
        <div class="col-12 conjunto-botoes">
          <button mat-flat-button color="primary" type="submit" id="buscar" name="buscar" class="btn-dark" [disabled]="!this.dadosFormulario.valid">Buscar</button>
          <button mat-button type="button" id="limpaForm" name="limpaForm" class="clear-filter text-secondary" (click)="limparFormulario()">Limpar filtros</button>
        </div>
      </div>
    </form>
    @if (mostraTabela) {
      <div>
        <br />
        <div class="table-responsive">
          <table class="tabelaPadrao">
            <thead>
              <tr>
                <th class="fw-bold text-center">
                  <input type="checkbox" (change)="selecionarTodos($event)" />
                </th>
                <th class="fw-bold text-center">Nome</th>
                <th class="fw-bold text-center">Status</th>
                <th class="fw-bold text-center">Data Envio</th>
              </tr>
            </thead>
            <tbody>
              @for (pedido of listaPedidos; track pedido) {
                <tr>
                  <td class="text-center">
                    <input type="checkbox" [checked]="pedido.podeDeferir && checkboxCabecalhoSelecionado" [disabled]="!pedido.podeDeferir" (change)="checarPedidoSelecionado(pedido)" />
                  </td>
                  <td class="text-center">
                    <a class="tipoLink" tabindex="0" (click)="avaliarPedido(pedido)" (keypress)="avaliarPedido(pedido)">{{ pedido.usuario.nome }}</a>
                  </td>
                  <td class="text-center">
                    <a class="tipoLink" tabindex="0" (click)="avaliarPedido(pedido)" (keypress)="avaliarPedido(pedido)"> {{ pedido.status.nome }} </a>
                  </td>
                  <td class="text-center">
                    <a class="tipoLink" tabindex="0" (click)="avaliarPedido(pedido)" (keypress)="avaliarPedido(pedido)"> {{ pedido.dataInscricao }}</a>
                  </td>
                </tr>
              }
            </tbody>
          </table>
          @if (!pedidosInscricao || pedidosInscricao.length === 0) {
            <div>
              <p class="text-center">Nenhum registro encontrado.</p>
            </div>
          }
        </div>
        <mat-paginator
          #paginator
          class="custom-paginator"
          aria-label="Select page"
          [length]="comprimento"
          [pageSize]="tamanhoDaPagina"
          [disabled]="desabilitado"
          [showFirstLastButtons]="mostrarPrimeiroUltimosBotoes"
          [pageSizeOptions]="mostrarOpcoesDeTamanhoPagina ? opcoesDoTamanhoDaPagina : []"
          [hidePageSize]="ocultarTamanhoDaPagina"
          [pageIndex]="numeroPagina"
          (page)="lidarEventoPagina($event)"
        />
        <br />
        <div class="d-flex row">
          <div class="col-12 conjunto-botoes">
            <button mat-button type="submit" class="btn btn-dark" [disabled]="ativaBotao" [ngClass]="{ 'disabled-button': ativaBotao }" (click)="deferirPedidos()">Deferir</button>
            <button mat-button type="submit" class="btn btn-dark" [disabled]="ativaBotao" [ngClass]="{ 'disabled-button': ativaBotao }" (click)="indeferirPedidos()">Indeferir</button>
          </div>
        </div>
      </div>
    }
  </div>
</div>
