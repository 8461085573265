import { Component, input } from '@angular/core';
import { MenuNode } from '../MenuNode';
import { MenuSguService } from '../MenuSgu.service';
import { MatExpansionPanel, MatExpansionPanelHeader } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { MatListItem, MatListItemIcon, MatListItemLine, MatNavList } from '@angular/material/list';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.sass',
  standalone: true,
  imports: [MatListItem, RouterLink, MatIcon, MatListItemIcon, MatListItemLine, MatExpansionPanel, MatExpansionPanelHeader, MatNavList]
})
export class MenuItemComponent {
  item = input.required<MenuNode>();
  menuAberto = input.required<boolean>();
  estaMostrando = input.required<boolean>();

  itemsVisiveis = new Set<number>();
  constructor(
    private menuSguService: MenuSguService,
    private route: Router
  ) {}
  alternarVisibilidade(item: MenuNode) {
    if (this.itemsVisiveis.has(item.id)) {
      this.itemsVisiveis.delete(item.id);
    } else {
      this.itemsVisiveis.add(item.id);
    }
  }

  eVisivel(item: MenuNode): boolean {
    return this.itemsVisiveis.has(item.id);
  }

  mudarRota(router: string = ''): string[] {
    return router ? [router] : [];
  }

  sobreItemClicado(): void {
    this.menuSguService.emitMenuItemclicado(this.item());
  }

  sair(route: string) {
    if (route == 'logout-site-pge') this.route.navigate(['area-restrita/logout-site-pge']);
  }

  abrirNovaAba(url: string): void {
    if (url.includes('http')) {
      let urlAlternativa = url;
      const token = localStorage.getItem('token');
      const idUsuario = localStorage.getItem('idUsuario');
      if (url.includes('{TOKENID}')) urlAlternativa = urlAlternativa.replace('{TOKENID}', token + idUsuario);
      window.open(urlAlternativa, '_blank');
    }
  }
}
