@if (!areaRestrita) {
  <app-fundo-area-restrita-pge>
    <form class="login-form card-style" [formGroup]="formularioAlterarSenha" (ngSubmit)="mudarSenha(false)">
      <div>
        <h1 class="title">Alteração de senha</h1>
        @if (senhaExpirada) {
          <h4 class="subtitle">Sua senha expirou.</h4>
          <h4 class="subtitle">Por favor, cadastre uma nova senha.</h4>
        }
      </div>
      <div class="input-label">
        <label class="label-align" for="senhaAntiga">SENHA ATUAL:</label>
        <div class="password-container">
          <input id="senhaAntiga" formControlName="senhaAntiga" required [type]="senhaAntigaVisivel ? 'text' : 'password'" />
          <mat-icon class="toggle-password" tabindex="0" aria-label="Toggle password visibility" (click)="toggleSenhaAntigaVisivel()" (keydown.enter)="toggleSenhaAntigaVisivel()">
            {{ senhaAntigaVisivel ? "visibility" : "visibility_off" }}
          </mat-icon>
        </div>
      </div>

      <div class="input-label">
        <label class="label-align" for="senhaNova">NOVA SENHA:</label>
        <div class="password-container">
          <input id="senhaNova" formControlName="senhaNova" required [type]="senhaNovaVisivel ? 'text' : 'password'" />
          <mat-icon class="toggle-password" tabindex="0" aria-label="Toggle password visibility" (click)="toggleSenhaNovaVisivel()" (keydown.enter)="toggleSenhaNovaVisivel()">
            {{ senhaNovaVisivel ? "visibility" : "visibility_off" }}
          </mat-icon>
        </div>
      </div>

      <div class="input-label">
        <label class="label-align" for="confirmaSenha">CONFIRMAR NOVA SENHA:</label>
        <div class="password-container">
          <input id="confirmaSenha" formControlName="confirmaSenha" required [type]="confirmaSenhaVisivel ? 'text' : 'password'" />
          <mat-icon class="toggle-password" tabindex="0" aria-label="Toggle password visibility" (click)="toggleConfirmaSenhaVisivel()" (keydown.enter)="toggleConfirmaSenhaVisivel()">
            {{ confirmaSenhaVisivel ? "visibility" : "visibility_off" }}
          </mat-icon>
        </div>
      </div>
      <div>
        @if (temError) {
          <div class="label-error">
            {{ mensagemError }}
          </div>
        }
        <button mat-raised-button color="primary" class="login-button" type="submit">Enviar</button>
        <button mat-raised-button color="primary" class="login-button" type="submit" (click)="voltar()">Voltar</button>
      </div>
      <mat-divider />
      <span class="help-message"><strong>Caso tenha algum problema em efetuar a alteração da senha, entre em contato com o administrador do sistema. </strong></span>
    </form>
  </app-fundo-area-restrita-pge>
} @else {
  <div class="novosass centralizado">
    <br />
    <h1 class="titulo-restrito">Alteração de Senha</h1>
    <form class="login-form card-style centralizado" [formGroup]="formularioAlterarSenhaRestricao" (ngSubmit)="mudarSenha(true)">
      <div class="col-md-6">
        <label for="senhaAntiga" class="form-label">SENHA ATUAL:<span class="text-danger requerido">*</span></label>
        <mat-form-field class="mat-form-control">
          <input matInput type="password" id="senhaAntiga" formControlName="senhaAntiga" class="input-tamanho" />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <label for="senhaNova" class="form-label">NOVA SENHA:<span class="text-danger requerido">*</span></label>
        <mat-form-field class="mat-form-control">
          <input matInput type="password" id="senhaNova" class="input-tamanho" formControlName="senhaNova" />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <label for="confirmaSenha" class="form-label">CONFIRMAR NOVA SENHA:<span class="text-danger requerido">*</span></label>
        <mat-form-field class="mat-form-control">
          <input matInput type="password" id="confirmaSenha" class="input-tamanho" formControlName="confirmaSenha" />
        </mat-form-field>
      </div>
      <div>
        @if (temError) {
          <div class="label-error-restrito">
            {{ mensagemError }}
          </div>
        }
        <br />
        <button mat-raised-button color="primary" class="btn-dark" type="submit">Enviar</button>
        <button mat-raised-button color="primary" class="btn-dark" type="submit" (click)="voltar()">Voltar</button>
      </div>
      <br />
      <mat-divider />
      <span class="help-message-restrito"><strong>Caso tenha algum problema em efetuar a alteração da senha, entre em contato com o administrador do sistema. </strong></span>
    </form>
  </div>
}
