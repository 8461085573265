import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { transformarData } from 'src/app/area-restrita/features/home/shared/utils/util';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { Noticias, pdfAnexo } from 'src/app/shared/models/noticia.interface';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-home-noticia',
  templateUrl: './home-noticia.component.html',
  styleUrl: './home-noticia.component.sass',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [MatIcon]
})
export class HomeNoticiaComponent implements OnInit, OnDestroy {
  noticia: Noticias;
  idNoticia: string;
  verificacaoCapaNoticia: boolean = false;
  arquivos: pdfAnexo[] = [];

  constructor(
    private noticiaService: NoticiaService,
    private route: ActivatedRoute,
    private imagemTratamento: ImagemTratamento,
    private base64ToPdfService: ConversorValidadorService,
    private toastr: ToastService,
    private paginaService: PaginaVisitadaService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.paginaService.salvaPagina('Lista de noticias').subscribe();
    this.idNoticia = this.route.snapshot.params['id'];
    this.buscarDados(this.idNoticia);
  }

  obterArquivos(idNoticia: number): void {
    this.noticiaService.obterArquivosNoticias(idNoticia).subscribe(
      (response) => {
        this.arquivos = response;
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
        this.toastr.error('Erro ao obter arquivos da notícia.');
      }
    );
  }

  buscarDados(id: string) {
    this.noticiaService.getOneNoticia(Number(id)).subscribe(
      (response) => {
        this.noticia = response;
        this.obterArquivos(Number(id));
        if (this.noticia.imagem && this.noticia.imagem.length > 0) {
          this.verificacaoCapaNoticia = true;
        }
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
        this.toastr.error('Erro ao obter notícia.');
      }
    );
  }

  obterImagem(noticiaImagem: string): string | undefined {
    return this.imagemTratamento.getImagemBlob(noticiaImagem);
  }

  editarData(data: string): string {
    return transformarData(data);
  }

  processarTexto(texto: string): string {
    if (texto) {
      return texto.replace(/<img\b[^>]*>/, (match) => match.replace('>', ' width="40%">'));
    } else {
      return '';
    }
  }

  AbrirArquivo(event: MouseEvent, pdf: pdfAnexo): void {
    event.preventDefault();
    this.base64ToPdfService.base64ToBlob(pdf.arquivo);
  }

  ngOnDestroy(): void {
    this.imagemTratamento.revogarUrlBlob();
  }
}
