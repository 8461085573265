import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatChipRow, MatChipSet } from '@angular/material/chips';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import { Noticias, pdfAnexo } from 'src/app/shared/models/noticia.interface';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-visualizar-noticia',
  templateUrl: './visualizar-noticia.component.html',
  styleUrl: './visualizar-noticia.component.sass',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [MatFormField, MatInput, MatRadioGroup, FormsModule, MatRadioButton, MatIcon, MatLabel, MatChipSet, MatChipRow]
})
export class VisualizarNoticiaComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  maxCaracteres: number = 50;
  idNoticia: string;
  campoCapa: boolean = false;
  campoAcesso: boolean = false;
  arquivos: pdfAnexo[] = [];
  campoDestaque: boolean = false;
  listaImagens: pdfAnexo[] = [];

  readonly palavrasChaves = signal([]);

  constructor(
    private noticiaService: NoticiaService,
    private route: ActivatedRoute,
    private imagemTratamento: ImagemTratamento,
    private location: Location,
    private base64ToPdfService: ConversorValidadorService,
    private toastr: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.idNoticia = this.route.snapshot.params['id'];
    this.buscarDados(this.idNoticia);
  }

  noticias: Noticias = {
    id: 0,
    titulo: '',
    texto: '',
    imagem: '',
    dataPublicacao: '',
    nomeArquivo: '',
    categoria: { id: 0, nome: '' },
    acesso: { id: 0, nome: '' },
    ativo: true,
    destaque: 0,
    palavraChave: ''
  };

  buscarDados(id: string) {
    this.noticiaService.getOneNoticiaAtivoTrueOrFalse(Number(id)).subscribe(
      (response) => {
        this.noticias = response;
        this.obterArquivos(Number(id));
        this.alternarAcessoRegistro();
        if (response.palavraChave) {
          const arrayDeString = response.palavraChave.split(' ');
          arrayDeString.forEach((string) => this.adicionar(string));
        }
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
        this.toastr.error('Erro ao obter notícia.');
      }
    );
  }

  obterArquivos(idNoticia: number): void {
    this.noticiaService.obterArquivosNoticias(idNoticia).subscribe(
      (response) => {
        response?.forEach((arquivoResponse) => (arquivoResponse.imagem ? this.listaImagens.push(arquivoResponse) : this.arquivos.push(arquivoResponse)));
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
        this.toastr.error('Erro ao obter arquivo notícia.');
      }
    );
    this.cdr.markForCheck();
  }

  adicionar(evento: string): void {
    const valor = (evento || '').trim();
    if (valor) {
      this.palavrasChaves.update((palavraChave) => [...palavraChave, valor]);
    }
  }

  alternarAcessoRegistro(): void {
    if (this.noticias.categoria.nome === 'Notícia Área Restrita') {
      this.campoAcesso = true;
      if (this.noticias.imagem !== '' || this.noticias.imagem != null) {
        this.campoCapa = true;
      }
    } else if (this.noticias.categoria.nome === 'Notícia Área Aberta') {
      this.campoCapa = true;
      this.campoDestaque = true;
    }
  }

  obterImagem(): string | undefined {
    return this.imagemTratamento.getImagemBlob(this.noticias.imagem);
  }

  processarTexto(texto: string): string {
    if (texto) {
      return texto.replace(/<img\b[^>]*>/, (match) => match.replace('>', ' width="40%">'));
    } else {
      return '';
    }
  }

  voltar() {
    this.location.back();
  }

  abrirArquivo(evento: MouseEvent, arquivoPdfOuImagem: pdfAnexo): void {
    evento.preventDefault();
    this.base64ToPdfService.base64ToBlob(arquivoPdfOuImagem.arquivo);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.imagemTratamento.revogarUrlBlob();
  }
}
