<div class="separator">
  <div [class]="msg4() === '' ? 'separador-bar-tres' : 'separador-bar-quatro'"></div>
  <div class="separator-content">
    <p class="separator-label">{{ label() }}</p>
    <p class="mensagem">{{ msg1() }}</p>
    <p class="mensagem">{{ msg2() }}</p>
    <p class="mensagem">{{ msg3() }}</p>
    <p class="mensagem">{{ msg4() }}</p>
  </div>
</div>
