import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix } from '@angular/material/form-field';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.sass',
  standalone: true,
  imports: [MatFormField, MatInput, FormsModule, ReactiveFormsModule, MatButton, MatSuffix, MatIcon]
})
export class SearchComponent implements OnInit {
  router: Router = inject(Router);
  searchControl = new FormControl('');
  constructor(private activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.getQueryParameter();
  }

  search() {
    const value = this.searchControl.value;
    const queryParam = this.activeRoute.snapshot.queryParams['search'];
    if (!value && queryParam) {
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }
    if (!value) return;
    this.router.navigate(['/'], { queryParams: { search: value } }).then(() => {
      window.location.reload();
    });
  }

  getQueryParameter() {
    this.activeRoute.queryParams.subscribe((params) => {
      this.searchControl.setValue(params['search']);
    });
  }
}
