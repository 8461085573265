<app-fundo-area-restrita-pge>
  <form class="login-form" (ngSubmit)="login()">
    <h1 class="title">Login</h1>
    <div class="input-label">
      <label class="label-align" for="usuario">USUÁRIO</label>
      <input type="text" id="usuario" name="usuario" required [(ngModel)]="usuario" />
    </div>
    <div class="input-label">
      <label class="label-align" for="senha">SENHA</label>
      <div class="password-container">
        <input id="senha" name="senha" required [type]="senhaVisivel ? 'text' : 'password'" [(ngModel)]="senha" />
        <mat-icon class="toggle-password" tabindex="0" aria-label="Toggle password visibility" (click)="toggleSenhaVisivel()" (keydown.enter)="toggleSenhaVisivel()">
          {{ senhaVisivel ? "visibility" : "visibility_off" }}
        </mat-icon>
      </div>
      <a class="forgot-password" tabindex="0" (click)="redirecionarParaRota($event)" (keydown.enter)="redirecionarParaRota($event)"> Esqueceu a senha? </a>
    </div>
    <button mat-raised-button color="primary" class="login-button" style="color: white !important" type="submit">Login</button>
    @if (eErro) {
      <div class="label-error">
        {{ mensagemErro }}
      </div>
    }
  </form>
  <span class="versao">{{ versao }}</span>
</app-fundo-area-restrita-pge>
