import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-wait-loading',
  templateUrl: './wait-loading.component.html',
  styleUrl: './wait-loading.component.css',
  standalone: true,
  imports: [MatProgressSpinner],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaitLoadingComponent {
  @Output() readonly fechar = new EventEmitter<void>();
  readonly label = inject<string>(MAT_DIALOG_DATA);

  onClose() {
    this.fechar.emit();
  }
}
