<div class="container-geral">
  <form class="form-container" [formGroup]="formComunicado" (ngSubmit)="enviarComunicacao()">
    <h3>Comunicar Erro</h3>
    <p>* Campos de preenchimento obrigatório.</p>

    <table>
      <tr>
        <td colspan="2">
          <div class="largura-campos">
            <mat-label for="nome">Nome *</mat-label>
            <mat-form-field>
              <input formControlName="nome" matInput placeholder="Informe seu nome completo" />
            </mat-form-field>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="largura-campos">
            <mat-label for="assunto">Assunto *</mat-label>
            <mat-form-field>
              <input formControlName="assunto" matInput placeholder="Informe o assunto" />
            </mat-form-field>
          </div>
        </td>
        <td>
          <div class="largura-campos">
            <mat-label for="email">Email *</mat-label>
            <mat-form-field>
              <input matInput formControlName="email" type="email" placeholder="Informe seu e-mail para contato" />
            </mat-form-field>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div>
            <mat-label for="texto">Mensagem *</mat-label>
            <div>
              <p-editor formControlName="texto" placeholder="Faça um relato sobre a correção a ser comunicada" class="texto" [style]="{ 'height.px': '500', 'width.%': '100' }" />
            </div>
          </div>
        </td>
      </tr>
    </table>

    @if (enviando) {
      <mat-spinner diameter="25" />
    }
    @if (!enviando) {
      <button class="button-reportar" type="submit" mat-raised-button color="primary">Reportar Erro</button>
    }
  </form>
  <div class="info-container">
    <p><strong>Ouvidoria SP</strong></p>
    <p>Canal de comunicação entre o cidadão e a administração pública com a finalidade de receber manifestações como sugestões, reclamações e denúncias. Entre em contato</p>
    <p><strong>Telefones Úteis</strong></p>
    <p>Tenha acesso e guarde os telefones dos serviços públicos estaduais. Veja mais</p>
    <p><strong>SIC</strong></p>
    <p>Deseja receber informações e documentos relacionados à administração pública paulista? Acesse o site do SIC</p>
  </div>
</div>
