import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { finalize, Subject, takeUntil } from 'rxjs';
import verificaCpf from 'src/app/area-aberta/features/home/noticias/shared/custom-validators/cpf.validator';
import dataNascimentoValidator from 'src/app/area-aberta/features/home/noticias/shared/custom-validators/dataNascimento.validor';
import { Dependente } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente';
import { FichaInscricaoCadastro } from 'src/app/area-restrita/features/auxilio/saude/shared/models/ficha-inscricao-cadastro';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { AuxilioSaude } from '../shared/models/auxilio-saude';
import { AuxilioSaudeService } from '../shared/services/auxilio-saude.service';
import { InscricaoAuxilioSaudeService } from '../shared/services/inscricao-auxilio-saude.service';
import FichaAuxilioHelper from './ficha-auxilio-saude.heper';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';

@Component({
  selector: 'app-ficha-auxilio-saude',
  templateUrl: './ficha-auxilio-saude.component.html',
  styleUrl: './ficha-auxilio-saude.component.sass',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatInput,
    NgxMaskDirective,
    MatCheckbox,
    MatButton,
    MatIcon,
    MatSelect,
    MatOption,
    ReactiveFormsModule,
    MatRadioGroup,
    MatRadioButton,
    MatProgressSpinner,
    MatLabel,
    DatePipe,
    NgxMaskPipe
  ]
})
export class FichaAuxilioSaudeComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  fichaAjudante = new FichaAuxilioHelper();
  mensagensErro = this.fichaAjudante.mensagensErro;
  opcoesRelacionamento = this.fichaAjudante.opcoesRelacionamento;
  mostrarFormulario: boolean = false;
  possuiInscricao: boolean = false;
  mostrarFrase: boolean = true;
  dependentes: Dependente[] = [];
  dependentesAuxiliar: Dependente[] = [];
  dependentesFichaInscricao: Dependente[] = [];
  selecionados: Set<Dependente> = new Set<Dependente>();
  mensagemErro: string = '';
  dataAlteracao: Date = null;
  mostrarCheckbox: boolean = false;
  fichaInscricaoCadastro: FichaInscricaoCadastro | null;
  ativaBotao: boolean = true;
  inscricaoIndeferida: boolean = false;
  pcd: boolean = false;

  dadosFormulario: AuxilioSaude = {
    id: '',
    rg: '',
    cpf: '',
    lotacao: '',
    endereco: '',
    cep: '',
    cidade: '',
    uf: '',
    telefone: '',
    celular: '',
    email: '',
    tipoPcd: { id: 0 },
    dependentes: [] as { id: number }[],
    usuario: { id: Number(localStorage.getItem('idUsuario')), nome: '' },
    dependentesImpostoRenda: false,
    contratantePlanoSaude: false,
    autorizacaoIAMSPE: false,
    possuoDependentes: false,
    dataAlteracao: new Date().toLocaleDateString()
  };

  dadosAvaliacao = {
    idUsuario: null,
    idFicha: '',
    justificativa: '',
    idStatus: 0,
    loading: false
  };

  auxilioStatusOpcoes = [
    {
      id: 2,
      name: 'Deferido'
    },
    {
      id: 3,
      name: 'Indeferido'
    }
  ];

  idUsuario: number;
  validacaoAvaliacao = false;

  constructor(
    private auxilioSaudeService: AuxilioSaudeService,
    private inscricaoAuxilioSaudeService: InscricaoAuxilioSaudeService,
    private toastr: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private paginaService: PaginaVisitadaService,
    private cdr: ChangeDetectorRef,
    private waitLoading: WaitLoadingService
  ) {
    this.idUsuario = this.route.snapshot.params['id'] || localStorage.getItem('idUsuario');
  }

  ngOnInit(): void {
    this.carregarDadosFichaAuxilio();
    this.paginaService.salvaPagina(this.validacaoAvaliacao ? 'Avaliação da Inscrição' : 'Ficha de Inscrição').subscribe();
  }

  formularioDependentes: FormGroup = new FormGroup({
    passo: new FormArray([
      new FormGroup({
        checked: new FormControl(false),
        nome: new FormControl('', [Validators.required]),
        cpfDependente: new FormControl('', [Validators.required, verificaCpf.bind(this)]),
        dataNascimento: new FormControl(null, [Validators.required, dataNascimentoValidator.bind(this)]),
        descricaoDependente: new FormControl('', [Validators.required]),
        usuario: new FormGroup({
          id: new FormControl(Number(localStorage.getItem('idUsuario')))
        })
      })
    ])
  });

  carregarDadosFichaAuxilio(): void {
    this.waitLoading.open();
    setTimeout(() => {
      this.validacaoAvaliacao = this.router.url.includes('inscricao-auxilio-avaliacao');
      this.auxilioSaudeService
        .carregarFichaAuxilioSaude(this.idUsuario)
        .pipe(finalize(() => this.waitLoading.close()))
        .pipe(takeUntil(this.destroy$))
        .subscribe(([fichaInscricaoResponse, inscricaoResponse, dependentesResponse]) => {
          this.dependentesAuxiliar = dependentesResponse;
          if (inscricaoResponse) {
            this.dadosFormulario = {
              id: inscricaoResponse.id || '',
              rg: inscricaoResponse.rg || '',
              cpf: inscricaoResponse.cpf || '',
              lotacao: inscricaoResponse.lotacao || '',
              endereco: inscricaoResponse.endereco || '',
              cep: inscricaoResponse.cep || '',
              cidade: inscricaoResponse.cidade || '',
              uf: inscricaoResponse.uf || '',
              telefone: inscricaoResponse.telefone || '',
              celular: inscricaoResponse.celular || '',
              email: inscricaoResponse.email || '',
              tipoPcd: inscricaoResponse.tipoPcd,
              dependentes: inscricaoResponse.dependentes || [],
              usuario: inscricaoResponse.usuario || { id: Number(localStorage.getItem('idUsuario')), nome: '' },
              dependentesImpostoRenda: inscricaoResponse.dependentesImpostoRenda || false,
              contratantePlanoSaude: inscricaoResponse.contratantePlanoSaude || false,
              autorizacaoIAMSPE: false,
              possuoDependentes: false,
              dataAlteracao: inscricaoResponse.dataAlteracao || new Date().toLocaleDateString()
            };

            this.fichaInscricaoCadastro = fichaInscricaoResponse;
            this.fichaInscricaoCadastro.nome = inscricaoResponse.usuario.nome;
            this.dadosFormulario.dependentesImpostoRenda = true;
            this.possuiInscricao = true;

            this.dadosAvaliacao.idUsuario = this.idUsuario.toString();
            this.dadosAvaliacao.justificativa = inscricaoResponse.justificativa;
            this.dadosAvaliacao.idFicha = inscricaoResponse.id;
            this.dadosAvaliacao.idStatus = Number(inscricaoResponse.status.id);

            if (inscricaoResponse.dataAlteracao) {
              const [data, hora] = inscricaoResponse.dataAlteracao.split(' ');
              const [dia, mes, ano] = data.split('/').map(Number);
              const [horas, minutos, segundos] = hora.split(':').map(Number);

              this.dataAlteracao = new Date(ano, mes - 1, dia, horas, minutos, segundos);
            }
            this.inscricaoIndeferida = inscricaoResponse.status.id == 3 || inscricaoResponse.status.id == 5 ? true : false;
            this.carregarDependentePreenchido();
          } else if (fichaInscricaoResponse) {
            this.fichaInscricaoCadastro = fichaInscricaoResponse;
            this.dadosFormulario.rg = fichaInscricaoResponse.rg;
            this.dadosFormulario.cpf = fichaInscricaoResponse.cpf;
            this.dadosFormulario.endereco = fichaInscricaoResponse.endereco;
            this.dadosFormulario.lotacao = fichaInscricaoResponse.lotacao;
            this.dadosFormulario.cep = fichaInscricaoResponse.cep;
            this.dadosFormulario.cidade = fichaInscricaoResponse.cidade;
            this.dadosFormulario.uf = fichaInscricaoResponse.uf;
            this.dadosFormulario.telefone = fichaInscricaoResponse.dddTelefone + fichaInscricaoResponse.telefone;
            this.dadosFormulario.celular = fichaInscricaoResponse.dddCelular + fichaInscricaoResponse.celular;
            this.dadosFormulario.email = fichaInscricaoResponse.email;
            this.dadosFormulario.dependentes = dependentesResponse.map((dep) => ({ ...dep }));
          }
          this.carregarDependentePreenchido();
          this.cdr.markForCheck();
        });
    }, 2000);
  }

  get passo(): FormArray {
    return this.formularioDependentes.get('passo') as FormArray;
  }

  adicionarLinhaGrid() {
    const ultimoGrupo = this.passo.at(this.passo.length - 1) as FormGroup;
    if (this.verificarCamposPrenhenchidosDependentes(ultimoGrupo)) {
      this.passo.push(
        new FormGroup({
          checked: new FormControl(false),
          nome: new FormControl(''),
          cpfDependente: new FormControl(''),
          dataNascimento: new FormControl(),
          descricaoDependente: new FormControl(''),
          usuario: new FormGroup({
            id: new FormControl(Number(localStorage.getItem('idUsuario')))
          })
        })
      );
    }
  }

  verificarCamposPrenhenchidosDependentes(formGroup: FormGroup): boolean {
    let retorno = true;

    for (const controle in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty.call(controle)) {
        const valor = formGroup.get(controle)?.value;
        if (valor === null || valor === undefined || valor === '') {
          retorno = false;
        }
      }
    }

    if (formGroup.status === 'INVALID' && formGroup.touched === true) {
      retorno = false;
    }

    return retorno;
  }

  carregarDependentePreenchido(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.dependentesAuxiliar.forEach((item) => {
        if (item.checked) {
          this.selecionados.add(item);
          this.dadosFormulario.autorizacaoIAMSPE = true;
          this.dadosFormulario.possuoDependentes = true;
        }
      });
      this.dependentes = this.dependentesAuxiliar;
      resolve();
    });
  }

  selecaoDependente(dependente: Dependente) {
    if (this.selecionados.has(dependente)) {
      this.selecionados.delete(dependente);
    } else {
      this.selecionados.add(dependente);
    }
  }

  formatarSaidaCPF(cpf: string): string {
    if (!cpf) return '';
    cpf = cpf.replace(/\D/g, '');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  excluirDependente(id: number): void {
    if (this.dadosFormulario.dependentes.length > 0) this.dadosFormulario.possuoDependentes = true;
    this.auxilioSaudeService.excluirDependente(id).subscribe(
      () => {
        this.toastr.success('Dependente(s) Excluído(s) com Sucesso');
        this.mostrarFormulario = false;
        this.mostrarFrase = true;
        this.atualizarDadosFichaInscricao();
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl(currentUrl);
        });
      },
      (error) => {
        console.error('Erro ao Apagar dado(s)', error);
      }
    );
  }

  enviarDadosDependentes() {
    if (this.verificarObjectCamposPreenchidosDependentes()) {
      const dadosParaEnviar = (this.passo.controls as FormGroup[])
        .map((grupo) => grupo.getRawValue())
        .map((e) => {
          const dataNascimento = new Date(e.dataNascimento);
          dataNascimento.setDate(dataNascimento.getDate() + 1);
          return {
            ...e,
            dataNascimento: dataNascimento.toLocaleDateString()
          };
        });

      this.auxilioSaudeService.enviarDadosDependentes(dadosParaEnviar as Dependente[]).subscribe(
        () => {
          this.mostrarFormulario = false;
          this.mostrarFrase = true;
          this.toastr.success('Enviado com sucesso');
          const urlAtual = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(urlAtual);
          });
          while (this.passo.length !== 0) {
            this.passo.removeAt(0);
          }
          this.passo.push(
            new FormGroup({
              nome: new FormControl(''),
              cpfDependente: new FormControl(''),
              dataNascimento: new FormControl(),
              descricaoDependente: new FormControl(''),
              usuario: new FormGroup({
                id: new FormControl(Number(localStorage.getItem('idUsuario')))
              })
            })
          );
        },
        (error) => {
          console.log(error);
          this.toastr.error(error);
        }
      );
    } else this.toastr.error('Favor preencher todos os campos');
  }

  verificarObjectCamposPreenchidosDependentes(): boolean {
    for (let i = 0; i < this.passo.length; i++) {
      const formGroup = this.passo.at(i) as FormGroup;
      if (!this.verificarCamposPrenhenchidosDependentes(formGroup)) {
        return false;
      }
    }
    return true;
  }

  incluirPcd(): void {
    this.auxilioSaudeService.incluirPcd(Number(localStorage.getItem('idUsuario')), this.pcd).subscribe({
      next: (response) => {
        console.log('Sucesso:', response);
      },
      error: (error) => console.error('Erro:', ` ${error.status} - ${error.error}`)
    });
  }

  enviarDadosFormulario() {
    if (this.camposObrigatoriosPreenchidos() && this.verificarObjectCamposPreenchidosDependentes()) {
      this.dadosFormulario.dependentes = [...this.selecionados].map((dependente) => ({ id: dependente.id }));
      this.auxilioSaudeService.enviarDadosFormulario(this.dadosFormulario).subscribe(
        () => {
          this.toastr.success('Dados enviados com Sucesso');
          if (this.dadosFormulario.tipoPcd.id < 8) {
            this.pcd = true;
          } else {
            this.pcd = false;
          }
          this.incluirPcd();
          this.limpaChecksBox();
          this.carregarDadosFichaAuxilio();
          this.enviarAtualizacaoStatus();
          this.mensagemErro = '';
        },
        (error) => {
          const errorMessage = error.erros ? error.erros['Erro de negócio'] : 'Verifique a ficha, erro ao efetuar inscrição.';
          this.toastr.warning(errorMessage);
        }
      );
    } else {
      this.mensagemErro = 'Falta preencher campos obrigatórios.';
    }
  }

  atualizarDadosFichaInscricao() {
    if (this.camposObrigatoriosPreenchidosEditar() && this.verificarObjectCamposPreenchidosDependentes()) {
      if (this.dadosFormulario.tipoPcd.id < 8) {
        this.pcd = true;
      } else {
        this.pcd = false;
      }
      this.dadosFormulario.dependentes = [...this.selecionados].map((dependente) => ({ id: dependente.id, checked: dependente.checked }));
      this.auxilioSaudeService.atualizarDadosFormulario(this.dadosFormulario).subscribe(
        () => {
          this.toastr.success('Dados atualizados com Sucesso');
          this.incluirPcd();
          this.limpaChecksBox();
          this.carregarDadosFichaAuxilio();
          this.enviarAtualizacaoStatus();
          this.mensagemErro = '';
        },
        (error: HttpErrorResponse) => {
          const errorMessage = error.error.erros['Erro de negócio'] ?? 'Verifique a ficha, erro ao efetuar inscrição.';
          this.toastr.warning(errorMessage, 'Erro de negócio');
        }
      );
    } else {
      this.toastr.error(null, 'Falta preencher campos obrigatórios.');
    }
  }

  camposObrigatoriosPreenchidos(): boolean {
    if (this.mostrarCheckbox && [...this.selecionados].length > 0) {
      return (
        this.dadosFormulario.celular !== '' &&
        this.dadosFormulario.email !== '' &&
        this.dadosFormulario.dependentesImpostoRenda &&
        this.dadosFormulario.contratantePlanoSaude &&
        this.dadosFormulario.autorizacaoIAMSPE &&
        this.dadosFormulario.possuoDependentes &&
        (this.dadosFormulario.tipoPcd?.id ?? 0) > 0
      );
    } else {
      return this.dadosFormulario.celular !== '' && this.dadosFormulario.email !== '' && this.dadosFormulario.dependentesImpostoRenda;
    }
  }

  camposObrigatoriosPreenchidosEditar(): boolean {
    if ([...this.selecionados].length > 0) {
      return (
        this.dadosFormulario.celular !== '' &&
        this.dadosFormulario.email !== '' &&
        this.dadosFormulario.dependentesImpostoRenda &&
        this.dadosFormulario.autorizacaoIAMSPE &&
        this.dadosFormulario.possuoDependentes &&
        (this.dadosFormulario.tipoPcd?.id ?? 0) > 0
      );
    } else {
      return this.dadosFormulario.celular !== '' && this.dadosFormulario.email !== '' && this.dadosFormulario.dependentesImpostoRenda;
    }
  }

  enviarAtualizacaoStatus() {
    if (!this.validacaoAvaliacao) return;
    const obsDeveSerPreenchido = this.dadosAvaliacao.idStatus == 3 && !this.dadosAvaliacao.justificativa;
    if (obsDeveSerPreenchido) {
      this.toastr.warning('Favor preencher a justificativa.');
      return;
    }
    this.auxilioSaudeService.enviarAtualizacaoStatus(Number(this.dadosFormulario.id), this.dadosAvaliacao);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  limpaChecksBox() {
    this.dadosFormulario.dependentesImpostoRenda = false;
    this.dadosFormulario.contratantePlanoSaude = false;
    this.dadosFormulario.autorizacaoIAMSPE = false;
    this.dadosFormulario.possuoDependentes = false;
  }

  removerLinhaDependente(i: number): void {
    const dependenteExcluir = this.dependentes[i];
    dependenteExcluir.checked = true;
    this.selecionados.delete(dependenteExcluir);
    this.excluirDependente(dependenteExcluir.id);
  }

  avaliarPedido(statusId: number) {
    switch (statusId) {
      case 2: {
        this.deferirIndeferirPedido();
        break;
      }
      case 3: {
        if (!this.dadosAvaliacao.justificativa || this.dadosAvaliacao.justificativa == '') {
          this.toastr.warning('Informe a justificativa para indeferir.');
          return;
        }
        this.deferirIndeferirPedido();
        break;
      }
      default: {
        this.toastr.warning('Opção status de avaliação inválido: ' + statusId);
      }
    }
  }

  desabilitarBotaoAvaliar(idStatus: number, justificativa: string) {
    return [2, 3].indexOf(idStatus) === -1 || (idStatus === 3 && (!justificativa || justificativa === ''));
  }

  deferirIndeferirPedido() {
    this.dadosAvaliacao.loading = true;

    this.inscricaoAuxilioSaudeService
      .deferirIndeferirPedido(this.dadosAvaliacao)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: string) => {
          this.dadosAvaliacao.loading = false;
          this.toastr.success(response);
          setTimeout(() => {
            this.router.navigate(['area-restrita', 'administracao-auxilio-saude', 'analisar-pedido']);
          }, 2000);
        },
        (error: HttpErrorResponse) => {
          this.dadosAvaliacao.loading = false;
          this.toastr.error('Ocorreu um erro ao deferir os pedidos: ' + error.message);
        },
        () => {
          this.dadosAvaliacao.loading = false;
        }
      );
  }
}
