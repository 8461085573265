import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, combineLatest, map, Observable, of, throwError } from 'rxjs';
import { Dependente } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente';
import { RelatorioDependenteResponse } from 'src/app/area-restrita/features/auxilio/saude/shared/models/relatorio';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { AuxilioApesp, AuxilioSaude, InscricaoAuxilioSaude, NotaFiscalAnexadaAuxilio, PedidoAuxilioSaude } from '../models/auxilio-saude';
import { AvaliacaoAuxilioDto } from '../models/auxilio-saude-dto';
import { FichaInscricaoCadastro } from '../models/ficha-inscricao-cadastro';

@Injectable({
  providedIn: 'root'
})
export class AuxilioSaudeService {
  constructor(private http: HttpClient) {}

  enviarDadosDependentes(dados: Dependente[]): Observable<Dependente[]> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    return this.http.post<Dependente[]>(Globais.urlToken + `site-pge/api/dependentes-auxilio-saude/batch`, dados, { headers: headers }).pipe(
      catchError((error) => {
        return throwError(error.error.message);
      })
    );
  }

  enviarDadosFormulario(dados: AuxilioSaude): Observable<InscricaoAuxilioSaude> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    return this.http.post<InscricaoAuxilioSaude>(Globais.urlToken + `site-pge/api/inscricoes-auxilio-saude`, dados, { headers: headers }).pipe(
      catchError((error) => {
        return throwError(error.error);
      })
    );
  }

  incluirPcd(idUsuario: number, pcd: boolean): Observable<string> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      Authorization: token
    });
    return this.http.put<string>(`${Globais.urlToken}site-pge/api/usuarios/${idUsuario}/pcd`, pcd, { headers: headers, responseType: 'text' as 'json' });
  }

  enviarAtualizacaoStatus(id: number, dadosAvaliacao) {
    return this.http.put(`${Globais.urlToken}site-pge/api/auxilios/${id}/avaliar-com-observacao`, { ...dadosAvaliacao });
  }

  cancelarPedidoAuxilioSaude(id: number) {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      Authorization: token
    });
    return this.http.put(`${Globais.urlToken}site-pge/api/auxilios/${id}/cancelar`, {}, { headers: headers, responseType: 'text' });
  }

  atualizarDadosFormulario(dados: AuxilioSaude): Observable<InscricaoAuxilioSaude> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    return this.http.put<InscricaoAuxilioSaude>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/${dados.id}`, dados, { headers: headers });
  }

  obterInscricao(idUser: number = null): Observable<InscricaoAuxilioSaude> {
    const idUsuario = idUser || localStorage.getItem('idUsuario');
    return this.http.get<InscricaoAuxilioSaude>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude?idUsuario=${idUsuario}`).pipe(
      map((req) => req),
      catchError((error) => {
        if (error.status === 404) {
          console.error('Não foi encontrado inscrição deste Usuário: ', error);
          return of(null);
        } else {
          console.error('Erro durante a obtenção de dados: ', error);
          return throwError('Falha na obtenção de dados');
        }
      })
    );
  }

  obterPedidoAuxilioSaude(idPedido: string): Observable<PedidoAuxilioSaude> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    return this.http.get<PedidoAuxilioSaude>(Globais.urlToken + 'site-pge/api/auxilios/' + idPedido, { headers: headers }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  obterListaDocumentos(id: string): Observable<NotaFiscalAnexadaAuxilio[]> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    return this.http.get<NotaFiscalAnexadaAuxilio[]>(Globais.urlToken + 'site-pge/api/notas-fiscais-anexadas/porIdAuxilio/' + id, { headers: headers }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  enviarAnalisePedidoAuxilioSaude(dados: AvaliacaoAuxilioDto, pedido: PedidoAuxilioSaude): Observable<string> {
    dados.idAprovador = localStorage.getItem('idUsuario');
    if (dados.valorDeferidoPge === pedido.valorReembolsoTotal) {
      dados.flagAvaliacao = 'A';
    } else if (dados.valorDeferidoPge == 0) {
      dados.flagAvaliacao = 'S';
    } else {
      dados.flagAvaliacao = 'I';
    }

    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    return this.http.put(Globais.urlToken + `site-pge/api/auxilios/saude/${pedido.id}/avaliacao`, dados, { headers: headers, responseType: 'text' }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  enviarRetificacaoAvaliarAuxilioSaude(dados: string, pedido: PedidoAuxilioSaude): Observable<string> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    return this.http
      .put(Globais.urlToken + `site-pge/api/auxilios/saude/${pedido.id}/retificar-avaliacao`, dados, { headers: headers, responseType: 'text' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /*    *** RelatorioPesquisaDependentes
   *** Uso no processo de pesquisa dos dependentes para busca e relatório. Não será esta rota, api, etc.       */
  RelatorioPesquisaDependentes(
    nomeTitular: string,
    cpfTitular: string,
    nomeDependente: string,
    cpfDependente: string,
    parentesco: string,
    pageNumber: number,
    pageSize: number
  ): Observable<RelatorioDependenteResponse> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      Authorization: token
    });

    const params = new HttpParams()
      .set('nomeTitular', nomeTitular?.trim().toUpperCase())
      .set('cpfTitular', cpfTitular?.trim().toUpperCase())
      .set('nomeDependente', nomeDependente?.trim().toUpperCase())
      .set('cpfDependente', cpfDependente?.trim().toUpperCase())
      .set('parentesco', parentesco?.trim().toUpperCase())
      .set('page', pageNumber)
      .set('size', pageSize == 0 ? 10 : pageSize);

    return this.http
      .get<RelatorioDependenteResponse>(`${Globais.urlToken}site-pge/api/auxilios/saude/consultar/relatorio/dependente`, { headers: headers, params: params })
      .pipe(
        catchError((error) => {
          console.error('Erro durante a obtenção de dependentes:', error);
          return throwError('Falha na obtenção de dependentes.');
        })
      );
  }

  relatorioPesquisaDependentesTipo(
    nomeTitular: string,
    cpfTitular: string,
    nomeDependente: string,
    cpfDependente: string,
    parentesco: string,
    tipoRelatorio: string
  ): Observable<Blob> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({ Authorization: token });
    const params = new HttpParams()
      .set('nomeTitular', nomeTitular.trim().toUpperCase())
      .set('cpfTitular', cpfTitular.trim().toUpperCase())
      .set('nomeDependente', nomeDependente.trim().toUpperCase())
      .set('cpfDependente', cpfDependente.trim().toUpperCase())
      .set('parentesco', parentesco.trim().toUpperCase());

    return this.http
      .get(`${Globais.urlToken}site-pge/api/auxilios/saude/exportar/relatorio/dependente/${tipoRelatorio}`, {
        headers: headers,
        params: params,
        responseType: 'blob'
      })
      .pipe(
        catchError((error) => {
          console.error('Erro durante a obtenção de dependentes:', error);
          return throwError('Falha na obtenção de dependentes.');
        })
      );
  }

  dadosFichaInscricao(idUsuario: number): Observable<FichaInscricaoCadastro> {
    return this.http.get<FichaInscricaoCadastro>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/consultar-usuario/${idUsuario}`).pipe(
      catchError((error) => {
        if (error.status === 404) {
          console.error('Não foi encontrado dados deste usuário: ', error);
          return of(null);
        } else {
          console.error('Erro durante a obtenção de dados: ', error);
          return throwError('Falha na obtenção de dados');
        }
      })
    );
  }

  excluirDependente(id: number): Observable<void> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    const option = {
      headers: headers
    };
    return this.http.delete<void>(Globais.urlToken + `site-pge/api/dependentes-auxilio-saude/${id}`, option).pipe(
      catchError((error) => {
        console.error('Erro durante a obtenção de dependentes:', error);
        return throwError('Falha na obtenção de dependentes.');
      })
    );
  }

  async inserirAuxilioSiafem(auxilioApesp: AuxilioApesp): Promise<void> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    try {
      const response = await this.http
        .post<void>(`${Globais.urlToken}site-pge/api/auxilios/saude/incluir/siafem`, auxilioApesp, { headers: headers })
        .toPromise();
      return response;
    } catch (error) {
      return error;
    }
  }

  async inserirAuxilioApesp(auxilioApesp: AuxilioApesp): Promise<boolean> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    try {
      const response = await this.http
        .post<boolean>(`${Globais.urlToken}site-pge/api/auxilios/saude/incluir/apesp`, auxilioApesp, { headers: headers })
        .toPromise();
      return response;
    } catch (error) {
      return error;
    }
  }

  obterListaDependentes(idUser: number = null): Observable<Dependente[]> {
    const idUsuario = idUser || localStorage.getItem('idUsuario');
    const params = new HttpParams().set('idUsuario', idUsuario);
    return this.http.get<Dependente[]>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/dependentes`, { params: params }).pipe(
      catchError((error) => {
        if (error.status === 404) {
          console.error('Não foi encontrado dependentes deste usuário: ', error);
          return of(null);
        } else {
          console.error('Erro durante a obtenção de dados: ', error);
          return throwError('Falha na obtenção de dados');
        }
      })
    );
  }

  carregarFichaAuxilioSaude(idUsuario: number): Observable<[FichaInscricaoCadastro, InscricaoAuxilioSaude, Dependente[]]> {
    return combineLatest([this.dadosFichaInscricao(idUsuario), this.obterInscricao(idUsuario), this.obterListaDependentes(idUsuario)]);
  }
}
