import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SeparadorEmailComponent } from '../../../core/layout/separador-email/separador-email.component';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-portal-precatorios',
  templateUrl: './portal-precatorios.component.html',
  styleUrl: './portal-precatorios.component.sass',
  standalone: true,

  imports: [SeparadorComponent, SeparadorEmailComponent, RouterLink]
})
export class PortalPrecatoriosComponent {}
