<app-separador label="Procurando Saber" />
<p class="espacamento-texto">
  O Projeto Procurando Saber é uma iniciativa criada em 2023 pela Procuradoria Geral do Estado de São Paulo (PGE/SP) em parceria com a Secretaria Estadual de Educação (SEDUC). O projeto tem o objetivo
  de difundir o conhecimento sobre o sistema de justiça brasileiro para estudantes do ensino médio da rede pública do Estado através de conversas e dinâmicas que fazem uso da linguagem simples e
  horizontalidade entre os procuradores voluntários e estudantes.
</p>

<div>
  <p-carousel [value]="cardImagem" [numVisible]="1" [numScroll]="1" [responsiveOptions]="responsiveConfig" [autoplayInterval]="6000" [circular]="true">
    <ng-template let-card pTemplate="item">
      <div class="imagem-carosel">
        <div class="image-wrapper">
          <img alt="Dinâmica Jogo dos Atores" [src]="card.src" />
        </div>
        <br />
        <div class="caption">{{ card.descricao }}</div>
      </div>
    </ng-template>
  </p-carousel>
</div>
<div class="accordion-menu">
  <div class="accordion-title" tabindex="0" (click)="toggle(0)" (keypress)="toggle(0)">
    <mat-icon class="red-icon">{{ ciclo[0].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
    {{ ciclo[0].title }}
  </div>
  @if (ciclo[0].isOpen) {
    <div class="accordion-content">
      <p>O primeiro ciclo do programa aconteceu no segundo semestre de 2023 e foi realizado em sete escolas da cidade de São Paulo, uma em Campinas, Piracicaba e São Carlos.</p>
      <div class="image-wrapper">
        <img src="images/procurando-saber/procurando-saber6.png" class="imagem" alt="Mapa" />
        <img src="images/procurando-saber/procurando-saber8.png" class="imagem" alt="Avaliação dos Alunos" />
      </div>
    </div>
  }
</div>

<div class="accordion-menu">
  <div class="accordion-title" tabindex="0" (click)="toggle(1)" (keypress)="toggle(1)">
    <mat-icon class="red-icon">{{ ciclo[1].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
    {{ ciclo[1].title }}
  </div>
  @if (ciclo[1].isOpen) {
    <div class="accordion-content">
      <p>Já o segundo ciclo aconteceu no primeiro semestre de 2024 e foi realizado em sete escolas da cidade de São Paulo, uma em Marília, Ourinhos, Ribeirão Preto e São José do Rio Preto.</p>
      <div class="image-wrapper">
        <img src="images/procurando-saber/procurando-saber7.png" class="imagem" alt="Mapa" />
        <img src="images/procurando-saber/procurando-saber9.png" class="imagem" alt="Avaliação dos Alunos" />
      </div>
    </div>
  }
</div>
<div class="caption"><b>Reportagem TV Cultura</b></div>
<div class="image-wrapper">
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/PtX4dIL8qyo?si=sQkEOljrt5xIefGr"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin"
    allowfullscreen
  ></iframe>
</div>
<h2>Avaliação dos Alunos</h2>
<div>
  <p-carousel [value]="cardContent" [numVisible]="1" [numScroll]="1" [responsiveOptions]="responsiveConfig" [autoplayInterval]="6000" [circular]="true">
    <ng-template let-card pTemplate="item">
      <div class="comentario">
        <div class="opniao">{{ card.opniao }}</div>
        <div class="caption" style="font-weight: bold">{{ card.autor }}</div>
      </div>
    </ng-template>
  </p-carousel>
</div>
