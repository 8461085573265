import { NgClass } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatNavList } from '@angular/material/list';
import { MatDrawer, MatDrawerContainer, MatDrawerContent } from '@angular/material/sidenav';
import { Router, RouterOutlet } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { MenuNode } from 'src/app/area-restrita/core/layout/menu/MenuNode';
import { MenuSguService } from 'src/app/area-restrita/core/layout/menu/MenuSgu.service';
import { AuthenticationService } from 'src/app/area-restrita/shared/services/authentication.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { UsuarioService } from '../../../features/home/shared/services/usuario.service';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';

@Component({
  selector: 'app-home-area-restrita',
  templateUrl: './home-area-restrita.component.html',
  styleUrl: './home-area-restrita.component.sass',
  standalone: true,
  imports: [MatIconButton, MatIcon, MatDrawerContainer, MatDrawer, NgClass, MatNavList, MenuItemComponent, MatDrawerContent, RouterOutlet]
})
export class HomeAreaRestritaComponent implements OnInit, OnDestroy {
  nomes = [];
  dataAtual: string;

  alternarMenuUsuario() {
    this.menuAtivo = !this.menuAtivo;
    if (this.menuAtivo) {
      this.resetMenu();
    }
  }
  manterMenu() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }
  fecharMenu() {
    this.timeoutHandle = setTimeout(() => {
      this.menuAtivo = false;
    }, 1000);
  }
  private resetMenu() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }
  sairUsuario() {
    this.router.navigate(['area-restrita/logout-site-pge']);
  }
  menuAtivo = false;
  private timeoutHandle: NodeJS.Timeout;
  @ViewChild('menuIcon') menuIcon: ElementRef;
  visibilityStates: Record<number, boolean> = {};
  menuAberto = true;
  estaMostrando = false;
  botaoFechar = false;
  private readonly destroy$ = new Subject<void>();
  idUsuario = localStorage.getItem('idUsuario');

  menuDataSubscription: Subscription;
  dataSource: MenuNode[] = [];
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private menuSguService: MenuSguService,
    private toastService: ToastService,
    private UsuarioService: UsuarioService
  ) {}

  ngOnInit() {
    this.menuSguService.getMenu();
    this.menuDataSubscription = this.menuSguService.menuData$.subscribe(async (menu) => {
      this.dataSource = menu;
    });
    this.dataAtual = this.obterDataAtual();
    this.obterNomeUsuario();
    this.opcaoSelecionada();
    this.menuSguService.emitMenuItemclicado$.subscribe(() => {
      this.alternarMenu(true);
    });
  }

  obterNomeUsuario() {
    this.UsuarioService.obterDadosUsuario(Number(localStorage.getItem('idUsuario'))).subscribe(
      (response) => {
        localStorage.setItem('nascimento', response.dataNascimento);
        localStorage.setItem('pcd', String(response.pcd));
        const responseNome = response.nome.trim();
        if (responseNome.indexOf(',') >= 0) this.nomes = response.nome.split(',');
        else if (responseNome.indexOf(' ') >= 0) this.nomes = response.nome.split(' ');
      },
      (error) => {
        console.log(error);
      }
    );
  }

  obterDataAtual(): string {
    const data = new Date();
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // Lembre-se que os meses começam em 0
    const ano = data.getFullYear();
    const hora = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');

    return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
  }

  ngOnDestroy() {
    if (this.menuDataSubscription) {
      this.menuDataSubscription.unsubscribe();
    }
  }
  eServicoMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      return true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }

    return false;
  }

  alternarMenu(botaoFechar: boolean, click: boolean = false) {
    if (click) {
      this.menuAberto = !this.menuAberto;
      this.estaMostrando = this.menuAberto;
    } else {
      if (this.menuAberto && !botaoFechar) {
        this.estaMostrando = true;
      } else {
        this.estaMostrando = false;
      }

      if (this.eServicoMobile()) {
        this.menuAberto = !this.menuAberto;
      }
    }
  }

  opcaoSelecionada() {
    if (this.eServicoMobile()) {
      this.menuAberto = false;
      this.estaMostrando = false;
      this.botaoFechar = true;
    } else {
      this.botaoFechar = false;
    }
  }

  alterarSenha() {
    const isRestrito = 'true';
    this.router.navigate(['area-restrita', 'alterar-senha-area-restrita'], {
      queryParams: { isRestrito: isRestrito }
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.opcaoSelecionada();
  }

  obterPrimeiroESegundoNome(): string {
    if (this.nomes.length > 1) {
      return `${this.nomes[0]} ${this.nomes[1]}`;
    }
    return this.nomes[0];
  }
}
