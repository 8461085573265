import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { Usuario } from '../../../auxilio/saude/shared/models/auxilio-saude';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  idUsuario = 0;
  constructor(private http: HttpClient) {
    this.idUsuario = Number(localStorage.getItem('idUsuario'));
  }

  obterDadosUsuario(idIusuario: number): Observable<Usuario> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    return this.http.get<Usuario>(Globais.urlToken + 'site-pge/api/usuarios/' + idIusuario, { headers: headers }).pipe(
      catchError((error) => {
        return throwError(error.error.message);
      })
    );
  }
}
