import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { LimiteMensal, LimiteMensalAuxilio } from '../../saude/shared/models/limite-mensal.model';

@Injectable({
  providedIn: 'root'
})
export class LimiteMensalService {
  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  consultaLimiteMensal(idPerfil: string, ano: number, mes: number, idIdade: number): Observable<LimiteMensalAuxilio> {
    const headers = new HttpHeaders({
      Authorization: this.localStorage.get('token')
    });
    const body = {
      ano: ano,
      mes: mes,
      idIdade: idIdade
    };
    return this.http.post<LimiteMensalAuxilio>(`${Globais.urlToken}site-pge/api/limites/atual/${idPerfil}`, body, { headers: headers }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  consultaHistorico(idPerfil: number, idIdade: number): Observable<LimiteMensal[]> {
    const headers = new HttpHeaders({
      Authorization: this.localStorage.get('token')
    });

    return this.http.get<LimiteMensal[]>(`${Globais.urlToken}site-pge/api/limites/historico/${idPerfil}/${idIdade}`, { headers: headers }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  criaLimiteMensal(idPerfil: number, ano: number, mes: number, idUsuario: number, valor: number, idIdade: number, unico: boolean): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.localStorage.get('token')
    });

    const body = {
      valor: valor,
      idUsuario: idUsuario,
      ano: ano,
      mes: mes,
      idIdade: idIdade,
      unico: unico
    };

    return this.http.post<string>(`${Globais.urlToken}site-pge/api/limites/${idPerfil}`, body, { headers: headers, responseType: 'text' as 'json' });
  }
}
