import { NgClass } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatDrawer, MatDrawerContainer } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DrawerContentComponent } from './area-aberta/core/layout/drawer-content/drawer-content.component';
import { FooterComponent } from './area-aberta/core/layout/footer/footer.component';
import { HeaderComponent } from './area-aberta/core/layout/header/header.component';
import { MenuComponent } from './area-aberta/core/layout/menu/menu.component';
import { SearchResultsComponent } from './area-aberta/core/layout/menu/search-results/search-results.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.sass',
  standalone: true,
  imports: [
    HeaderComponent,
    MenuComponent,
    SearchResultsComponent,
    FooterComponent,
    DrawerContentComponent,
    MatDrawer,
    MatDrawerContainer,
    MatIcon,
    RouterOutlet,
    NgClass
  ]
})
export class AppComponent implements OnInit {
  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  title = 'Site-PGE-FrontEnd';
  routasSemHeader = ['/painel-area-externa', '/login', '/esqueci-senha', '/alterar-senha', '/area-restrita'];
  search = '';
  visibilidade: boolean = false;

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.search = params['search'];
    });
  }
  isRootRoute(): boolean {
    return this.route.url === '/';
  }
  notLoginForm(): boolean {
    if (this.routasSemHeader.includes(this.route.url) || this.route.url.indexOf('/area-restrita') >= 0) {
      return false;
    }
    return true;
  }

  getConfigValue(): string {
    return environment.env;
  }

  openMenu(): void {
    const config: MatDialogConfig = {
      height: '100%',
      width: '75%',
      position: { left: '0' },
      data: { search: this.search }
    };
    this.dialog.open(MenuComponent, config);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth > 480) {
      this.dialog.closeAll();
    }
  }
}
