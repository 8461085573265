import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { NgxMaskDirective } from 'ngx-mask';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-momento-virtual-procurador',
  templateUrl: './momento-virtual-procurador.component.html',
  styleUrl: './momento-virtual-procurador.component.sass',
  standalone: true,

  imports: [SeparadorComponent, NgxMaskDirective, MatButton]
})
export class MomentoVirtualProcuradorComponent {}
