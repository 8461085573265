<div class="home-info">
  <div class="div-noticia">
    <p class="title-noticia">{{ noticia?.titulo }}</p>
    <p class="data-noticia" [innerHTML]="editarData(noticia?.dataPublicacao)"></p>
    @if (verificacaoCapaNoticia) {
      <p><img alt="Imagem da Notícia" class="imagem-noticia" [src]="obterImagem(noticia?.imagem)" /></p>
    }
    <p class="text-noticia" [innerHTML]="processarTexto(noticia?.texto)"></p>
    <div class="arquivo">
      <table>
        <tbody>
          @for (item of arquivos; track item; let i = $index) {
            <tr>
              <td>
                <a href="#" (click)="AbrirArquivo($event, item)"><mat-icon fontIcon="cloud_download" />{{ item.nome }}</a>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
