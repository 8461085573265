import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-sobre-ouvidoria',
  templateUrl: './sobre-ouvidoria.component.html',
  styleUrl: './sobre-ouvidoria.component.css',
  standalone: true,

  imports: [SeparadorComponent]
})
export class SobreOuvidoriaComponent {
  emailPrecatorio: string = 'precatoriospgeatendimento@sp.gov.br';
}
