import { Component } from '@angular/core';
import { MapaSiteComponent } from './mapa-site/mapa-site.component';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.sass',
  standalone: true,
  imports: [MapaSiteComponent]
})
export class FooterComponent {
  readonly versao = Globais.versionFront;
}
