import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/area-restrita/shared/services/authentication.service';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { LegadoUsuarioService } from '../../auxilio/shared/services/legado-usuario.service';
import { SenhaService, TrocaSenha } from '../shared/services/senha.service';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
import { MatDivider } from '@angular/material/divider';
import { MatButton } from '@angular/material/button';
import { FundoAreaRestritaPgeComponent } from '../shared/components/fundo-area-restrita-pge/fundo-area-restrita-pge.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-alterar-senha-card',
  templateUrl: './alterar-senha-card.component.html',
  styleUrl: './alterar-senha-card.component.sass',
  standalone: true,

  imports: [FundoAreaRestritaPgeComponent, FormsModule, ReactiveFormsModule, MatButton, MatDivider, MatFormField, MatInput, MatIconModule]
})
export class AlterarSenhaCardComponent implements OnInit {
  areaRestrita: boolean;
  senhaExpirada = true;
  temError = false;
  mensagemError = '';

  senhaAntigaVisivel = false;
  senhaNovaVisivel = false;
  confirmaSenhaVisivel = false;

  formularioAlterarSenha: FormGroup;
  formularioAlterarSenhaRestricao: FormGroup;

  constructor(
    private fb: FormBuilder,
    private senhaService: SenhaService,
    private toastr: ToastService,
    private localStorage: LocalStorageService,
    private legadoUsuarioService: LegadoUsuarioService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.formularioAlterarSenha = this.fb.group({
      senhaAntiga: ['', Validators.required],
      senhaNova: ['', [Validators.required]],
      confirmaSenha: ['', Validators.required]
    });

    this.formularioAlterarSenhaRestricao = this.fb.group({
      senhaAntiga: ['', Validators.required],
      senhaNova: ['', [Validators.required]],
      confirmaSenha: ['', Validators.required]
    });

    this.areaRestrita = this.route.snapshot.params['isRestrito'] === 'true';
    this.route.queryParams.subscribe((params) => {
      this.areaRestrita = params['isRestrito'] === 'true'; // Converter para booleano
    });
  }

  mudarSenha(restrito: boolean): void {
    this.temError = false;
    const form = restrito ? this.formularioAlterarSenhaRestricao : this.formularioAlterarSenha;

    if (form.invalid) {
      this.mensagemError = 'Todos os campos devem ser preenchidos';
      this.temError = true;
      return;
    }

    const { senhaAntiga, senhaNova, confirmaSenha } = form.value;

    if (senhaNova !== confirmaSenha) {
      this.mensagemError = 'Senha digitada não é igual à nova senha!';
      this.temError = true;
      return;
    }

    if (senhaAntiga === senhaNova) {
      this.mensagemError = 'A nova senha não pode ser igual à antiga';
      this.temError = true;
      return;
    }

    const trocaSenha: TrocaSenha = { senhaAntiga, senhaNova };
    this.senhaService.alterarSenha(trocaSenha).subscribe(
      () => {
        this.verificarUsuarioNoLegado(trocaSenha);
      },
      (error) => {
        this.exibirErro('Falha ao trocar senha no sistema atual. A senha permanece inalterada.', error);
      }
    );
  }

  verificarUsuarioNoLegado(trocaSenha: TrocaSenha): void {
    const config = new MatSnackBarConfig();
    config.duration = 5000;

    const cpfUsuario = this.localStorage.get('cpf');
    if (!cpfUsuario) {
      this.exibirErro('Erro: CPF do usuário não encontrado.');
      return;
    }
    this.legadoUsuarioService.checkUsuarioLegado(cpfUsuario).subscribe(
      () => {
        this.trocaSenhaNoLegado(trocaSenha);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.toastr.info('Usuário não encontrado no legado, será levado para o novo sistema somente!', 'Aviso do Sistema', config);
        this.authenticationService.logout('/login');
      }
    );
  }

  trocaSenhaNoLegado(trocaSenha: TrocaSenha): void {
    const config = new MatSnackBarConfig();
    config.duration = 5000;

    const cpfUsuario = this.localStorage.get('cpf');
    if (!cpfUsuario) {
      this.exibirErro('Erro: CPF do usuário não encontrado.');
      return;
    }
    this.legadoUsuarioService.enviarNovaSenhaParaLegado(cpfUsuario, trocaSenha.senhaNova).subscribe(
      () => {
        this.toastr.info('Senha alterada com sucesso nos dois sistemas!', 'Aviso do Sistema', config);
        this.authenticationService.logout('/login');
      },
      (error: HttpErrorResponse) => {
        this.reverterSenhaNoNovoAmbiente(trocaSenha);
        this.exibirErro('Erro ao trocar senha no sistema legado. A senha permanece inalterada em ambos os sistemas.', error);
      }
    );
  }

  reverterSenhaNoNovoAmbiente(trocaSenha: TrocaSenha): void {
    const senhaOriginal: TrocaSenha = { senhaAntiga: trocaSenha.senhaNova, senhaNova: trocaSenha.senhaAntiga };
    this.senhaService.alterarSenha(senhaOriginal).subscribe(
      () => {
        console.log('Reversão da senha no novo ambiente bem-sucedida.');
      },
      (error) => {
        console.error('Falha ao reverter a senha no novo ambiente.', error);
      }
    );
  }

  exibirErro(mensagem: string, error?: HttpErrorResponse): void {
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    this.temError = true;
    this.mensagemError = mensagem;
    this.toastr.error(mensagem, 'Erro', config);
    console.error('Erro:', error?.message || 'Erro desconhecido');
  }

  voltar(): void {
    this.router.navigate(['area-restrita/home']);
  }

  toggleSenhaAntigaVisivel() {
    this.senhaAntigaVisivel = !this.senhaAntigaVisivel;
  }

  toggleSenhaNovaVisivel() {
    this.senhaNovaVisivel = !this.senhaNovaVisivel;
  }

  toggleConfirmaSenhaVisivel() {
    this.confirmaSenhaVisivel = !this.confirmaSenhaVisivel;
  }
}
