<div class="container">
  <br />
  <h1 class="titulo">Criar Noticia</h1>
  <br />
  <div>
    <form [formGroup]="formulario" (ngSubmit)="enviarFormulario()">
      <div class="d-flex row">
        <div class="col-md-12">
          <label for="titulo">Título da Notícia:</label>
          <mat-form-field>
            <input id="titulo" matInput type="text" formControlName="titulo" />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex row">
        <div class="col-md-4">
          <label for="dataPublicacao">Data de Publicação:</label>
          <div class="mat-form-control">
            <input id="dataPublicacao" type="datetime-local" class="input-date" formControlName="dataPublicacao" />
          </div>
        </div>
        <div class="col-md-3">
          <label for="categoria">Categoria:</label>
          <mat-form-field class="mat-form-control">
            <mat-select id="categoria" formControlName="categoria" (selectionChange)="alternarAcessoRegistro()">
              @for (item of objetoCategoria; track item) {
                <mat-option [value]="item.value">
                  {{ item.nome }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        @if (campoDestaque) {
          <div class="col-md-3">
            <label for="destaque">Destaque:</label>
            <mat-form-field class="mat-form-control">
              <mat-select id="destaque" formControlName="destaque">
                @for (item of objetoDestaque; track item) {
                  <mat-option [value]="item.value">
                    {{ item.nome }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        }
      </div>
      @if (campoAcesso) {
        <div class="col-md-9">
          <label for="acesso">Acesso Registro:</label>
          <mat-radio-group id="acesso" formControlName="acesso" aria-label="Select an option">
            <mat-radio-button [value]="1">Geral</mat-radio-button>
            <mat-radio-button [value]="2">Procuradores</mat-radio-button>
            <mat-radio-button [value]="3">Servidores</mat-radio-button>
          </mat-radio-group>
        </div>
      }
      <div>
        <p-editor formControlName="texto" class="texto" [style]="{ 'height.px': '500', 'width.%': '100' }" />
      </div>
      <br />
      <div>
        <mat-label>Palavras-chave:</mat-label>
        <div class="d-flex row">
          <div class="col-md-6">
            <mat-form-field>
              <input #palavraChaveInput matInput name="palavraAtual" type="text" [matChipInputFor]="chipGrid" />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <button type="button" class="botao" (click)="adicionarPalavra(palavraChaveInput.value)">Inserir</button>
          </div>
        </div>
        <mat-chip-grid #chipGrid aria-label="palavraChave selecionada">
          @for (palavraChave of palavrasChaves(); track $index) {
            <mat-chip-row (removed)="removerPalavra(palavraChave)">
              {{ palavraChave }}
              <button matChipRemove type="submit" [attr.aria-label]="'remove ' + palavraChave">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          }
        </mat-chip-grid>
      </div>
      <div class="alinhar-colunas">
        @if (campoCategoriaAbertaRestrita) {
          <div class="div-pdf">
            <label for="capaNoticia">Capa da Notícia:</label>
            <div class="arquivo-nome-vertical">
              <ngx-file-drop
                id="capaNoticia"
                dropZoneLabel="Adicionea a imagem ou solte o arquivo aqui"
                browseBtnLabel="Browse files"
                browseBtnClassName="send-files btn btn-secondary m-2 gray-file-drop"
                [showBrowseBtn]="true"
                [multiple]="false"
                [accept]="'image/*'"
                (onFileDrop)="imagemSelecionada($event)"
              />
              <div>
                <table>
                  <tbody>
                    <tr class="arquivo-alinhar">
                      <td>{{ this.formulario.controls["nomeArquivo"].getRawValue() }}</td>
                      <td>
                        @if (this.formulario.controls["nomeArquivo"].getRawValue()) {
                          <mat-icon fontIcon="cancel" (click)="removerimagem()" />
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
        <div class="div-pdf">
          <label for="pdfs">Anexar PDFs:</label>
          <div class="arquivo-nome-vertical">
            <ngx-file-drop
              id="pdfs"
              dropZoneLabel="Adicione um ou mais arquivos ou solte os arquivos aqui"
              browseBtnLabel="Browse files"
              browseBtnClassName=" send-files btn btn-secondary m-2 gray-file-drop"
              [showBrowseBtn]="true"
              (onFileDrop)="arquivosSelecionados($event, false)"
            />
            <div>
              <table>
                <tbody>
                  @for (item of pdf; track item; let i = $index) {
                    <tr class="arquivo-alinhar">
                      <td>{{ item.nome }}</td>
                      <td>
                        <mat-icon fontIcon="cancel" (click)="removerArquivo(i, false)" />
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        @if (campoCategoriaAbertaRestrita) {
          <div>
            <label for="imagens">Anexar imagens:</label>
            <div class="arquivo-nome-vertical">
              <ngx-file-drop
                id="imagens"
                dropZoneLabel="Adicione um ou mais imagens ou solte as imagens aqui"
                browseBtnLabel="Browse files"
                browseBtnClassName=" send-files btn btn-secondary m-2 gray-file-drop"
                [showBrowseBtn]="true"
                [accept]="'image/*'"
                (onFileDrop)="arquivosSelecionados($event, true)"
              />
              <div>
                <table>
                  <tbody>
                    @for (item of listaImagens; track item; let i = $index) {
                      <tr class="arquivo-alinhar">
                        <td>{{ item.nome }}</td>
                        <td>
                          <mat-icon fontIcon="cancel" (click)="removerArquivo(i, true)" />
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
      </div>
      <br />
      <br />
      <div class="botoes">
        <button type="button" class="btn btn-outline-dark" (click)="cancelarEnvio()">Voltar</button>&nbsp;
        <button mat-flat-button type="submit" class="botao">Enviar Notícia</button>
      </div>
    </form>
    <br />
  </div>
</div>
